import { render, staticRenderFns } from "./BookGroupModal.vue?vue&type=template&id=5630c035&"
import script from "./BookGroupModal.vue?vue&type=script&lang=ts&"
export * from "./BookGroupModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5630c035')) {
      api.createRecord('5630c035', component.options)
    } else {
      api.reload('5630c035', component.options)
    }
    module.hot.accept("./BookGroupModal.vue?vue&type=template&id=5630c035&", function () {
      api.rerender('5630c035', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order-book-group/components/BookGroupModal.vue"
export default component.exports