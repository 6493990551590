"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/foods",
    method: "get",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/foods",
    method: "post",
    data: data
  });
}

function update(foodId, data) {
  return (0, _request.default)({
    url: "/foods/".concat(foodId, "/update"),
    method: "post",
    data: data
  });
}