var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Chọn ngày")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "To",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc"
                },
                on: { change: _vm.handleQuery },
                model: {
                  value: _vm.date,
                  callback: function($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Mã đơn")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm mã đơn"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  }
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Đợt đặt sách")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Đợt đặt sách" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.strategyOrderId,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "strategyOrderId", $$v)
                    },
                    expression: "listQuery.strategyOrderId"
                  }
                },
                _vm._l(_vm.strategyOrders, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Phòng GD&ĐT")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "Phòng GD&ĐT" },
                  on: { change: _vm.handleChangeDivision },
                  model: {
                    value: _vm.listQuery.divisionId,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "divisionId", $$v)
                    },
                    expression: "listQuery.divisionId"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.divisions, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.name + " (" + item.addressCity.name + ")",
                        value: item.id
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trường học")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "Trường học" },
                  on: { change: _vm.fetchTableData },
                  model: {
                    value: _vm.listQuery.schoolId,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "schoolId", $$v)
                    },
                    expression: "listQuery.schoolId"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.schools, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.name + " (" + item.addressCity.name + ")",
                        value: item.id
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái giao hàng")
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái giao hàng" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.deliveryStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "deliveryStatus", $$v)
                    },
                    expression: "listQuery.deliveryStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.PreOrderDeliveryStatus, function(item, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: item, value: key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Hình thức thanh toán")
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Hình thức thanh toán" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.paymentType,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "paymentType", $$v)
                    },
                    expression: "listQuery.paymentType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.PaymentTypeTrans, function(item, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: item, value: key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái thanh toán")
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái thanh toán" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.paymentStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "paymentStatus", $$v)
                    },
                    expression: "listQuery.paymentStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.PaymentStatus, function(item, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: item, value: key }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.fetchTableData }
            },
            [_vm._v(" Tìm ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                loading: _vm.loadingExcel
              },
              on: { click: _vm.exportExcel }
            },
            [_vm._v(" Xuất excel ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { prop: "code", width: "180", label: "Mã đơn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(row.code))
                      ]),
                      row.isDeleted
                        ? _c(
                            "div",
                            [
                              _c("el-tag", { attrs: { type: "danger" } }, [
                                _vm._v(" Hủy đơn ")
                              ])
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm.renderTypeStatus(
                                      row.deliveryStatus
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.OrderStatusMerge[
                                          row.deliveryStatus
                                        ] || "Không xác định"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              row.deliveryStatus != "COMPLETE"
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "8px" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "150px" },
                                          attrs: {
                                            value: row.deliveryStatus,
                                            placeholder: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleChangeStatus(
                                                $event,
                                                row
                                              )
                                            }
                                          }
                                        },
                                        _vm._l(
                                          _vm.PreOrderDeliveryStatus,
                                          function(item, key) {
                                            return _c("el-option", {
                                              key: key,
                                              attrs: { label: item, value: key }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Học sinh" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Họ tên: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.studentName))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Ngày sinh: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.studentDob))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Họ tên phụ huynh: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.parentName))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Số điện thoại phụ huynh: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.parentPhone))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Lớp: ")]),
                        _c("span", [
                          _vm._v(_vm._s(row.classroom && row.classroom.name))
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Đợt đặt sách" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.strategyOrder.name))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tổng tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "detail-order" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng số lượng sách:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(
                                _vm.getTotalQuantity(row)
                              )
                            )
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng tiền:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.moneyFinal))
                          )
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Thanh toán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(
                            row.paymentType == "CASH" ? "Tiền mặt" : "VNPay"
                          )
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.renderPaymentStatus(row.paymentStatus)
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.PaymentStatus[row.paymentStatus] ||
                                      "Không xác định"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$formatDateTime(row.createdAt)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "80",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start"
                        }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Chi tiết đơn",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleView(row)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Xuất excel",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-download"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exportExcelDetail(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchTableData
        }
      }),
      _c("OrderDetailModal", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }