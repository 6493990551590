var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm tên danh mục"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchTableData($event)
                  }
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Cấu hình hoa hồng")
              ]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: { change: _vm.fetchTableData },
                  model: {
                    value: _vm.listQuery.isCommission,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "isCommission", $$v)
                    },
                    expression: "listQuery.isCommission"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "Có cấu hình", value: true }
                  }),
                  _c("el-option", {
                    attrs: { label: "Không cấu hình", value: false }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.fetchTableData()
                }
              }
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.levelSelected != 8 && !_vm.queryLevel["parent" + 8 + "Id"]
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v(" Thêm mới ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm._l(_vm.categoryLevels, function(categoryLevel, i) {
            return [
              categoryLevel.level == 1 ||
              _vm.queryLevel["parent" + (categoryLevel.level - 1) + "Id"]
                ? _c(
                    "div",
                    {
                      key: i,
                      staticClass: "filter-item",
                      staticStyle: { "margin-right": "5px" }
                    },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cấp " + _vm._s(categoryLevel.level))
                      ]),
                      _c(
                        "el-select",
                        {
                          on: {
                            change: function($event) {
                              return _vm.handleChangeCategoryLevel(
                                $event,
                                categoryLevel.level
                              )
                            }
                          },
                          model: {
                            value:
                              _vm.queryLevel[
                                "parent" + categoryLevel.level + "Id"
                              ],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.queryLevel,
                                "parent" + categoryLevel.level + "Id",
                                $$v
                              )
                            },
                            expression:
                              "queryLevel[`parent${categoryLevel.level}Id`]"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: undefined }
                          }),
                          _vm._l(categoryLevel.categories, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      ),
      _c(
        "el-table",
        { ref: "table", attrs: { data: _vm.tableData, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { prop: "level", label: "Cấp", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "Tên", width: "270" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticClass: "image",
                      staticStyle: { "vertical-align": "middle" },
                      attrs: {
                        width: "40",
                        src: _vm.$baseUrlMedia + row.thumbnail,
                        alt: ""
                      }
                    }),
                    _c(
                      "span",
                      { staticStyle: { "vertical-align": "middle" } },
                      [_vm._v(_vm._s(row.name))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Ưu tiên", width: "80", prop: "priority" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "Phần trăm hoa hồng cấp 1 (%)",
              align: "center",
              width: "80",
              prop: "refCommissionPercent"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.refCommissionPercent) + "% ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Phần trăm hoa hồng cấp 2 (%)",
              align: "center",
              width: "80",
              prop: "childRefCommissionPercent"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.childRefCommissionPercent) + "% ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Đường dẫn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.renderBreadcrumb(row)) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Nổi bật" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.level == 1
                      ? _c(
                          "div",
                          [
                            !row.isHighlight
                              ? _c("el-button", {
                                  attrs: {
                                    icon: "el-icon-star-off",
                                    type: "primary",
                                    size: "mini",
                                    loading: row.loadingStar
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleChangeHightlight(row)
                                    }
                                  }
                                })
                              : _c("el-button", {
                                  staticStyle: { color: "yellow" },
                                  attrs: {
                                    icon: "el-icon-star-on",
                                    type: "warning",
                                    size: "mini",
                                    loading: row.loadingStar
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleChangeHightlight(row)
                                    }
                                  }
                                })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Hiển thị trong app" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      attrs: { value: row.isShowInApp },
                      on: {
                        change: function($event) {
                          return _vm.handleChangeShowInApp(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "150",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết",
                          placement: "top",
                          effect: "dark"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleUpdate(row, false)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    !row.isBlock
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Xóa",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleDelete(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchTableData
        }
      }),
      _c("CategoryBookModal", {
        ref: "dialog",
        attrs: {
          "query-level": _vm.queryLevel,
          "level-selected": _vm.levelSelected
        },
        on: {
          "submit:success": function($event) {
            _vm.fetchTableData()
            _vm.handleChangeCategoryLevel(
              _vm.parentIdSelected,
              _vm.levelSelected
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }