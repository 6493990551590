"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var configApi = {
  findAll: function findAll() {
    return (0, _request.default)({
      url: "/configuration"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/configuration/".concat(id, "/update"),
      method: "post",
      data: data
    });
  }
};
exports.configApi = configApi;