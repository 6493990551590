"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _classRoom = require("@/api/class-room");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["schoolId", "onTransfer"],
  data: function data() {
    return {
      visible: false,
      form: {},
      classrooms: [],
      rules: {
        classroomId: {
          message: "Phải chọn lớp",
          required: true,
          trigger: "submit"
        }
      },
      loading: false
    };
  },
  watch: {
    schoolId: {
      handler: function handler() {
        this.fetchClassrooms();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleOpen: function handleOpen() {
      this.visible = true;
      this.form = {};
    },
    transfer: function transfer() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _this.onTransfer(_this.form.classroomId).then(function () {
            _this.visible = false;
          }).finally(function () {
            return _this.loading = false;
          });
        }
      });
    },
    fetchClassrooms: function fetchClassrooms() {
      var _this2 = this;

      _classRoom.classRoomApi.findAll({
        schoolId: this.schoolId
      }).then(function (res) {
        _this2.classrooms = res.data.data;
      });
    }
  }
};
exports.default = _default;