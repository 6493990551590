var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BarHorizontalChart", {
        ref: "chart",
        attrs: {
          "data-chart": _vm.dataChart,
          series: _vm.series,
          xAxisValue: "name"
        },
        on: {
          "update:dataChart": function($event) {
            _vm.dataChart = $event
          },
          "update:data-chart": function($event) {
            _vm.dataChart = $event
          }
        }
      }),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v(" Top 10 sách bán chạy nhất trong tháng ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }