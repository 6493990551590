"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.joinDivision = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _JoinDivision = _interopRequireDefault(require("./JoinDivision"));

var _OutDivision = _interopRequireDefault(require("./OutDivision"));

var _All = _interopRequireDefault(require("./All"));

var _vuex = require("vuex");

var _ContactBook = _interopRequireDefault(require("./ContactBook.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var joinDivision = {
  In: "IN",
  Out: "OUT",
  All: "ALL"
};
exports.joinDivision = joinDivision;
var _default = {
  components: {
    All: _All.default,
    JoinDivision: _JoinDivision.default,
    OutDivision: _OutDivision.default,
    ContactBook: _ContactBook.default
  },
  created: function created() {
    this.$store.dispatch("address/fetchCities");
  }
};
exports.default = _default;