var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.status == "create"
            ? "Thêm " + (_vm.form.type === "BOOK" ? "sách" : "đồng phục")
            : "Cập nhật " +
              (_vm.form.type === "BOOK" ? "sản phẩm" : "đồng phục"),
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.form,
                    "label-position": "top",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tên", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "", size: "medium" },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.form.type != "CLOTHING"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Mã sản phẩm", prop: "code" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "", size: "medium" },
                                    model: {
                                      value: _vm.form.code,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "code", $$v)
                                      },
                                      expression: "form.code",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        left: "0",
                                        bottom: "-27px",
                                        "font-size": "12px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Kioviet ID: " +
                                          _vm._s(_vm.form.kvId || "--")
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Giá gốc", prop: "originPrice" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.originPrice,
                                    expression: "form.originPrice",
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }",
                                  },
                                ],
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.form.originPrice },
                                on: {
                                  change: _vm.handleChangePrice,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "originPrice",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Loại" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "",
                                    size: "medium",
                                    disabled: this.form.type == "CLOTHING",
                                  },
                                  model: {
                                    value: _vm.form.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "type", $$v)
                                    },
                                    expression: "form.type",
                                  },
                                },
                                _vm._l(_vm.BookType, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: _vm.BookTypeTrans[item],
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giá sau khuyến mãi",
                                prop: "finalPrice",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.finalPrice,
                                    expression: "form.finalPrice",
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }",
                                  },
                                ],
                                attrs: { type: "text", placeholder: "" },
                                domProps: { value: _vm.form.finalPrice },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "finalPrice",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          this.form.type != "CLOTHING"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Tồn kho" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "quantity", $$v)
                                      },
                                      expression: "form.quantity",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.form.type === "BOOK"
                                    ? "Tình trạng"
                                    : "Ấn/hiển thị trên web",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.form.isOutOfStock,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isOutOfStock", $$v)
                                    },
                                    expression: "form.isOutOfStock",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.type === "BOOK"
                                          ? "Hết hàng"
                                          : "Ẩn"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.form.isOutOfStock,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isOutOfStock", $$v)
                                    },
                                    expression: "form.isOutOfStock",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.type === "BOOK"
                                          ? "Còn hàng"
                                          : "Hiển thị"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.form.type != "CLOTHING"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Phần trăm hoa hồng cấp 1 (%)",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }",
                                      },
                                    ],
                                    model: {
                                      value: _vm.form.refCommissionPercent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "refCommissionPercent",
                                          $$v
                                        )
                                      },
                                      expression: "form.refCommissionPercent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      this.form.type != "CLOTHING"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Phần trăm hoa hồng cấp 2 (%)",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{\n                  alias: 'decimal',\n                  groupSeparator: '.',\n                  rightAlign: false,\n                  autoGroup: true,\n                  repeat: 20,\n                  autoUnmask: true,\n                  allowMinus: false,\n                }",
                                      },
                                    ],
                                    model: {
                                      value: _vm.form.childRefCommissionPercent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "childRefCommissionPercent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.childRefCommissionPercent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Danh mục", prop: "categoryId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    "popper-append-to-body": false,
                                  },
                                  model: {
                                    value: _vm.form.categoryId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "categoryId", $$v)
                                    },
                                    expression: "form.categoryId",
                                  },
                                },
                                _vm._l(_vm.categories, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.id,
                                      staticStyle: {
                                        overflow: "unset !important",
                                        "max-width": "500px",
                                      },
                                      attrs: {
                                        label: _vm.renderBreadcrumb(item),
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticStyle: { margin: "0" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.renderBreadcrumb(item)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  rows: 10,
                                  type: "textarea",
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.form.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.type !== "CLOTHING"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Thuộc tính", name: "property" } },
                [_c("product-property", { ref: "productProperty" })],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "Hình ảnh", name: "thumbnail" } },
            [_c("Thumbnail", { ref: "thumbnail" })],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.$checkAction("clothing-index-update")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }