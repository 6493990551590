"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dashboard = require("@/api/dashboard");

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      data: {},
      summary: {},
      strategyOrder: {},
      commissionSchool: 0,
      commissionDivision: 0
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      _dashboard.dashboardApi.preOrderSummary().then(function (res) {
        if (!res.data) {
          return _this.$emit('dataNull');
        }

        _this.strategyOrder = res.data.strategyOrder;
        _this.summary = res.data.summary;
        _this.commissionSchool = res.data.commissionSchool;
        _this.commissionDivision = res.data.commissionDivision;
      });
    }
  }
};
exports.default = _default;