var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingModal,
          expression: "loadingModal"
        }
      ],
      staticClass: "category-book-modal",
      attrs: {
        "close-on-click-modal": false,
        title: _vm.status == "create" ? "Thêm danh mục" : "Cập nhật danh mục",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
        fullscreen: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phòng GD", prop: "divisionId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", filterable: "" },
                          model: {
                            value: _vm.form.divisionId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "divisionId", $$v)
                            },
                            expression: "form.divisionId"
                          }
                        },
                        _vm._l(_vm.divisions, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.status != "create"
            ? _c(
                "el-form-item",
                { attrs: { label: "Sách", prop: "bookIds" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "Danh mục" },
                      on: {
                        change: function($event) {
                          return _vm.fetchBooks()
                        }
                      },
                      model: {
                        value: _vm.categoryId,
                        callback: function($$v) {
                          _vm.categoryId = $$v
                        },
                        expression: "categoryId"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Tất cả", value: undefined }
                      }),
                      _vm._l(_vm.categories, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.id,
                            staticStyle: {
                              overflow: "unset !important",
                              "max-width": "500px"
                            },
                            attrs: {
                              label: _vm.renderBreadcrumb(item),
                              value: item.id
                            }
                          },
                          [
                            _c("p", { staticStyle: { margin: "0" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.renderBreadcrumb(item)) + " "
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        placeholder: "",
                        multiple: "",
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.fetchBooks
                      },
                      on: { "remove-tag": _vm.handleRemoveBook },
                      model: {
                        value: _vm.form.bookIds,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bookIds", $$v)
                        },
                        expression: "form.bookIds"
                      }
                    },
                    _vm._l(_vm.booksBig, function(book) {
                      return _c("el-option", {
                        key: book.id,
                        attrs: {
                          label: _vm.renderTitleBook(book),
                          value: book.id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }