"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _grade = require("@/api/grade");

var _GradeModal = _interopRequireDefault(require("./components/GradeModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    GradeModal: _GradeModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.fetchTableData();
  },
  methods: {
    renderCategories: function renderCategories(categories) {
      return categories.map(function (e) {
        return e.name;
      }).join(",");
    },
    handleUpdate: function handleUpdate(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleCreate: function handleCreate() {
      this.$refs["dialog"].handleCreate();
    },
    handleBlock: function handleBlock(row) {
      var _this = this;

      this.$confirm("Khối này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _grade.gradeApi.remove(row.id).then(function () {
          _this.$notify.success({
            message: "Xoá thành công",
            title: "Thông báo"
          });

          _this.fetchTableData();
        });
      });
    },
    fetchTableData: function fetchTableData() {
      var _this2 = this;

      this.listLoading = true;

      _grade.gradeApi.findAll(this.listQuery).then(function (res) {
        _this2.tableData = res.data.data;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;