var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            "margin-bottom": "16px"
          }
        },
        [
          _c(
            "label",
            {
              staticStyle: { "text-transform": "uppercase" },
              attrs: { for: "" }
            },
            [_vm._v("Đợt đặt sách: ")]
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "300px", "margin-left": "8px" },
              attrs: { placeholder: "" },
              on: { change: _vm.fetchDetail },
              model: {
                value: _vm.strategyOrderId,
                callback: function($$v) {
                  _vm.strategyOrderId = $$v
                },
                expression: "strategyOrderId"
              }
            },
            _vm._l(_vm.strategyOrders, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.strategyOrderId
        ? [
            _c("Summary", {
              attrs: {
                "strategy-order": _vm.report.strategyOrder,
                "money-commission": _vm.report.moneyCommission,
                summary: _vm.report.summary,
                "commission-school": _vm.report.commissionSchool,
                "commission-division": _vm.report.commissionDivision
              },
              on: { "update:success": _vm.showRefreshData }
            }),
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.tabName,
                  callback: function($$v) {
                    _vm.tabName = $$v
                  },
                  expression: "tabName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Tổng quan", name: "general" } },
                  [
                    _c("SummaryBook", {
                      attrs: { "summary-book": _vm.report.summaryBook }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Dự trù đầu cấp", name: "preorder" } },
                  [
                    _c("PreOrderReport", {
                      ref: "pre-order-report",
                      attrs: {
                        "pre-order-id": _vm.strategyOrderId,
                        "refresh-data": _vm.refreshData
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Phòng GD&ĐT", name: "division" } },
                  [
                    _c("DivisionReport", {
                      ref: "division-report",
                      attrs: {
                        "pre-order-id": _vm.strategyOrderId,
                        "cancel-submit": _vm.cancelSubmit,
                        "loading-cancel": _vm.loadingCancel,
                        "refresh-data": _vm.refreshData
                      },
                      on: { "load:school": _vm.handleDetailSchool }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Trường", name: "school" } },
                  [
                    _c("SchoolReport", {
                      ref: "school-report",
                      attrs: {
                        "pre-order-id": _vm.strategyOrderId,
                        "cancel-submit": _vm.cancelSubmit,
                        "loading-cancel": _vm.loadingCancel,
                        "refresh-data": _vm.refreshData,
                        "division-id": _vm.divisionId
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Khối", name: "grade" } },
                  [
                    _c("SummaryGrade", {
                      attrs: { "summary-grade": _vm.report.grades }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "h3",
              { staticClass: "text-center", staticStyle: { color: "red" } },
              [_vm._v(" Vui lòng chọn đợt đặt sách ")]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }