"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _school = require("@/api/school");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["schoolId"],
  data: function data() {
    return {
      loading: false,
      form: {},
      visible: false,
      status: "create",
      rules: {
        month: {
          required: true,
          message: "Vui lòng nhập số tháng",
          trigger: "submit"
        },
        amount: {
          required: true,
          message: "Vui lòng nhập số tiền",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    handleCreate: function handleCreate() {
      this.status = "create";
      this.visible = true;
      this.form = {};
    },
    createData: function createData() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = (0, _objectSpread2.default)({}, _this.form);
          _this.loading = true;

          _school.schoolApi.renew(_this.schoolId, data).then(function () {
            _this.$notify.success({
              message: "Gia hạn thành công",
              title: "Thông báo"
            });

            _this.visible = false;

            _this.$emit("submit:success");
          }).finally(function () {
            return _this.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;