"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.array.find");

var _categoryBook = require("@/api/category-book");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["details", "onPressAdd", "onPressDelete", "statusModal"],
  data: function data() {
    return {
      categories: []
    };
  },
  created: function created() {
    this.fetchCategories();
  },
  methods: {
    handleChangeCateogry: function handleChangeCateogry(index, $event) {
      var flag = false;
      var detail = this.details.find(function (e) {
        return $event == e.categoryId;
      });

      if (detail) {
        flag = true;
      }

      if (flag) {
        return this.$message.error("Danh mục này đã chọn rồi");
      }

      this.$set(this.details, index, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.details[index]), {}, {
        categoryId: $event
      }));
    },
    fetchCategories: function fetchCategories() {
      var _this = this;

      _categoryBook.categoryBookApi.findAll().then(function (res) {
        _this.categories = res.data.data;
      });
    }
  }
};
exports.default = _default;