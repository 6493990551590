"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.withdrawApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var withdrawApi = {
  findAll: findAll,
  create: create,
  approve: function approve(id) {
    return (0, _request.default)({
      url: "/withdraw/".concat(id, "/approve"),
      method: "put"
    });
  },
  reject: function reject(id) {
    return (0, _request.default)({
      url: "/withdraw/".concat(id, "/reject"),
      method: "put"
    });
  }
};
exports.withdrawApi = withdrawApi;

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/withdraw",
    method: "get",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/withdraw",
    method: "post",
    data: data
  });
}