import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=9008676c&"
import script from "./Summary.vue?vue&type=script&lang=js&"
export * from "./Summary.vue?vue&type=script&lang=js&"
import style0 from "./Summary.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9008676c')) {
      api.createRecord('9008676c', component.options)
    } else {
      api.reload('9008676c', component.options)
    }
    module.hot.accept("./Summary.vue?vue&type=template&id=9008676c&", function () {
      api.rerender('9008676c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/strategy-order/detail/components/Summary.vue"
export default component.exports