"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _strategyOrder = require("@/api/strategy-order");

var _StrategyOrderModal = _interopRequireDefault(require("./components/StrategyOrderModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    StrategyOrderModal: _StrategyOrderModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.fetchTableData();
  },
  methods: {
    handleDetail: function handleDetail(row) {
      this.$router.push({
        path: "/preOrder/detail",
        query: {
          id: row.id
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleCreate: function handleCreate() {
      this.$refs["dialog"].handleCreate();
    },
    handleBlock: function handleBlock(row) {
      var _this = this;

      this.$confirm("Chiến dịch này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _strategyOrder.strategyOrderApi.remove(row.id).then(function () {
          _this.$notify.success({
            message: "Xoá thành công",
            title: "Thông báo"
          });

          _this.fetchTableData();
        });
      });
    },
    fetchTableData: function fetchTableData() {
      var _this2 = this;

      this.listLoading = true;

      _strategyOrder.strategyOrderApi.findAll(this.listQuery).then(function (res) {
        _this2.tableData = res.data.data;
        _this2.total = res.data.total;
      }).finally(function () {
        _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;