import { render, staticRenderFns } from "./OrderSummary.vue?vue&type=template&id=517907a8&"
import script from "./OrderSummary.vue?vue&type=script&lang=js&"
export * from "./OrderSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('517907a8')) {
      api.createRecord('517907a8', component.options)
    } else {
      api.reload('517907a8', component.options)
    }
    module.hot.accept("./OrderSummary.vue?vue&type=template&id=517907a8&", function () {
      api.rerender('517907a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/admin/components/PanelGroup/OrderSummary.vue"
export default component.exports