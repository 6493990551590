var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "-20px" } },
    [
      _c("Student", {
        ref: "Student",
        attrs: { "classroom-id": _vm.classroom.id },
        on: { onTransfer: _vm.handleTransfer }
      }),
      _c("TransferClassModal", {
        ref: "TransferClassModal",
        attrs: { "school-id": _vm.school.id, "on-transfer": _vm.transfer }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }