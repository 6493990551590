var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "pre-order-grade-book-modal",
      attrs: {
        "close-on-click-modal": false,
        title: "Cập nhật sách khối " + _vm.dialogTitle,
        visible: _vm.visible,
        width: "900px",
        top: "30px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px"
          }
        },
        [
          _vm._l(_vm.form.details, function(detail, i) {
            return _c(
              "div",
              { key: i, staticClass: "panel-detail" },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8, offset: 0 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Tiêu đề" } },
                          [
                            _c("el-input", {
                              attrs: {
                                autosize: { minRows: 1 },
                                type: "textarea"
                              },
                              model: {
                                value: detail.title,
                                callback: function($$v) {
                                  _vm.$set(detail, "title", $$v)
                                },
                                expression: "detail.title"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8, offset: 0 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Phòng GD" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", clearable: "" },
                                on: {
                                  change: function($event) {
                                    detail.schoolId = null
                                    _vm.fetchSchools(detail.divisionId)
                                  }
                                },
                                model: {
                                  value: detail.divisionId,
                                  callback: function($$v) {
                                    _vm.$set(detail, "divisionId", $$v)
                                  },
                                  expression: "detail.divisionId"
                                }
                              },
                              _vm._l(_vm.divisions, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8, offset: 0 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Trường" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { filterable: "", clearable: "" },
                                model: {
                                  value: detail.schoolId,
                                  callback: function($$v) {
                                    _vm.$set(detail, "schoolId", $$v)
                                  },
                                  expression: "detail.schoolId"
                                }
                              },
                              _vm._l(_vm.schools, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Nhóm sách" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { filterable: "", clearable: "" },
                        model: {
                          value: detail.bookGroupId,
                          callback: function($$v) {
                            _vm.$set(detail, "bookGroupId", $$v)
                          },
                          expression: "detail.bookGroupId"
                        }
                      },
                      _vm._l(_vm.bookGroups, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                detail.bookGroupId
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "Sách thuộc nhóm sách" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "",
                              multiple: "",
                              disabled: "",
                              "reserve-keyword": "",
                              value: _vm
                                .renderBooksFromBookGroup(detail.bookGroupId)
                                .map(function(e) {
                                  return e.id
                                })
                            }
                          },
                          _vm._l(
                            _vm.renderBooksFromBookGroup(detail.bookGroupId),
                            function(book) {
                              return _c("el-option", {
                                key: book.id,
                                attrs: {
                                  label: _vm.renderTitleBook(book),
                                  value: book.id
                                }
                              })
                            }
                          ),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "Sách" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "",
                          multiple: "",
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          "remote-method": _vm.fetchBooks
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleChangeBook($event, detail)
                          }
                        },
                        model: {
                          value: detail.bookIds,
                          callback: function($$v) {
                            _vm.$set(detail, "bookIds", $$v)
                          },
                          expression: "detail.bookIds"
                        }
                      },
                      _vm._l(_vm.booksBig, function(book) {
                        return _c("el-option", {
                          key: book.id,
                          attrs: {
                            label: _vm.renderTitleBook(book),
                            value: book.id
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("el-button", {
                  attrs: {
                    type: "danger",
                    size: "mini",
                    icon: "el-icon-delete",
                    disabled: _vm.form.details.length == 1
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDetailGrade(_vm.form, i)
                    }
                  }
                })
              ],
              1
            )
          }),
          _c(
            "el-button",
            {
              staticStyle: { "z-index": "1", "margin-top": "12px" },
              attrs: { type: "primary", size: "mini", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.handleAddDetailGrade(_vm.form)
                }
              }
            },
            [_c("span", [_vm._v("Thêm chi tiết")])]
          )
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }