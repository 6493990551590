var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "preorder-grade" } },
    [
      _vm.summaryPreOrder && _vm.summaryPreOrder.preOrderDetails.length
        ? _c(
            "div",
            {
              staticClass: "filter-container",
              staticStyle: { "margin-bottom": "16px" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v(" Xuất excel ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.summaryPreOrder && _vm.summaryPreOrder.preOrderDetails.length
        ? _c("div", { staticClass: "product-list" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "body-card" },
              [
                [
                  _vm._l(_vm.summaryPreOrder.preOrderDetails, function(
                    item,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index, staticClass: "product-item" },
                      [
                        _c("div", { staticClass: "product-name" }, [
                          _c("span", [_vm._v(" " + _vm._s(item.book.name))])
                        ]),
                        _c("div", { staticClass: "price" }, [
                          _c("div", { staticClass: "space-between" }, [
                            _c("span", { staticClass: "origin-price" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(item.book.finalPrice)
                                  ) +
                                  "đ "
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c("div", { staticClass: "space-between" }, [
                            _c("span", { staticClass: "origin-price" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$formatNumberVN(item.quantity)) +
                                  " "
                              )
                            ])
                          ])
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "space-between" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "retail-price font-bold color-green"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatNumberVN(item.totalPrice)
                                    ) +
                                    "đ "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm.summaryPreOrder &&
                  _vm.summaryPreOrder.preOrderDetails.length
                    ? _c("div", { staticClass: "summary" }, [
                        _c("div"),
                        _c("div"),
                        _c("div"),
                        _c("div", [
                          _c("div", { staticClass: "space-between" }, [
                            _c("label", [_vm._v("Tổng cộng: ")]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "retail-price font-bold color-green"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatNumberVN(_vm.form.totalPrice)
                                    ) +
                                    "đ "
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          ])
        : [
            _c("h3", { staticClass: "text-center" }, [
              _vm._v(" Chưa có đơn dự trù ")
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-card" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Sản phẩm")]),
      _c("label", { attrs: { for: "" } }, [_vm._v("Đơn giá")]),
      _c("label", { attrs: { for: "" } }, [_vm._v("Số lượng")]),
      _c("label", { attrs: { for: "" } }, [_vm._v("Thành tiền")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }