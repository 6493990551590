"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    summary: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    totalCommission: {
      type: Number
    }
  }
};
exports.default = _default2;