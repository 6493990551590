var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Lịch sử chỉnh sửa đơn hàng (#" + _vm.order.code + ")",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: { data: _vm.transformData, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "Thời gian" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Nhân viên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.staff
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(row.staff.username) +
                              " - " +
                              _vm._s(row.staff.name) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.action == "CREATE" ? "Tạo mới" : "Cập nhật"
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Nội dung" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticStyle: { "padding-left": "15px" } },
                      _vm._l(row.contents, function(item, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(item) + " ")
                        ])
                      }),
                      0
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }