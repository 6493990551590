"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _address = require("@/api/address");

var _DistrictShipModal = _interopRequireDefault(require("./components/DistrictShipModal"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    DistrictShipModal: _DistrictShipModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    cities: function cities(state) {
      return state.address.cities;
    }
  })),
  created: function created() {
    this.fetchTableData();
  },
  methods: {
    handleViewDistrict: function handleViewDistrict(row) {
      this.$refs.dialog.handleOpen(row);
    },
    handleSave: function handleSave(row) {
      var _this = this;

      this.listLoading = true;

      _address.addressApi.updateCity(row.id, {
        addressCity: {
          feeDelivery: row.tmpFeeDelivery
        }
      }).then(function () {
        _this.$message.success("Cập nhật phí ship thành công");

        _this.$set(row, "isEdit", false);

        _this.$set(row, "feeDelivery", row.tmpFeeDelivery);
      }).finally(function () {
        return _this.listLoading = false;
      });
    },
    handleCancel: function handleCancel(row) {
      this.$set(row, "isEdit", false);
    },
    handleUpdate: function handleUpdate(row) {
      this.$set(row, "isEdit", true);
      this.$set(row, "tmpFeeDelivery", row.feeDelivery);
    },
    handleCreate: function handleCreate() {
      this.$refs["dialog"].handleCreate();
    },
    fetchTableData: function fetchTableData() {
      var _this2 = this;

      this.listLoading = true;
      this.$store.dispatch("address/fetchCities").finally(function () {
        return _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;