var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "text-align": "center" } },
    [
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _vm.pathImage
          ? _c("img", {
              staticStyle: { "object-fit": "contain" },
              style: "width:" + _vm.width + ";height:" + _vm.height,
              attrs: { src: _vm.$baseUrlMedia + _vm.pathImage }
            })
          : _vm._e()
      ]),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleChooseMedia } },
        [_vm._v(" Chọn từ kho media ")]
      ),
      _c("ListMediaModal", {
        ref: "media-modal",
        attrs: { "on-choose": _vm.onChooseMedia }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }