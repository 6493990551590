var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, fit: "" } },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" }
          }),
          _c("el-table-column", { attrs: { label: "Sách", prop: "name" } }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Số lượng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toThousandFilter")(row.total)) + " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v("Top 10 sách bán chạy nhất trong tháng")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }