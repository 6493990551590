"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _ContactBook = _interopRequireDefault(require("../ContactBook.vue"));

//
//
//
//
//
//
var _default = {
  components: {
    ContactBook: _ContactBook.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    school: function school(state) {
      return state.school.selected;
    }
  }))
};
exports.default = _default;