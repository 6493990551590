"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _enum = require("@/utils/enum");

var _order = require("@/api/order");

var _vuex = require("vuex");

var _lodash = require("lodash");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      OrderStatus: _enum.OrderStatus,
      form: {},
      visible: false,
      loading: false,
      oldForm: {}
    };
  },
  computed: (0, _objectSpread2.default)({
    getTotalQuantity: function getTotalQuantity() {
      var sum = 0;
      this.data.orderDetails.forEach(function (e) {
        sum += e.quantity;
      });
      return sum;
    }
  }, (0, _vuex.mapState)({
    cities: function cities(state) {
      return state.address.cities;
    }
  })),
  created: function created() {
    this.$store.dispatch("address/fetchCities");
  },
  methods: {
    updateData: function updateData() {
      var _this = this;

      if ((0, _lodash.isEqual)(this.form, this.oldForm)) {
        return this.visible = false;
      }

      this.loading = true;

      _order.orderApi.update(this.form.id, {
        order: this.form
      }).then(function (res) {
        _this.$notify.success({
          title: "Thông báo",
          message: "Cập nhật thành công"
        });

        _this.$emit("submit:success");

        _this.visible = false;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    handleUpdate: function handleUpdate(form) {
      this.form = (0, _objectSpread2.default)({}, form);
      this.oldForm = (0, _objectSpread2.default)({}, form);
      this.visible = true;
    }
  }
};
exports.default = _default;