"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;
exports.remove = remove;
exports.notice = notice;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/notification",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/notification",
    method: "post",
    data: data
  });
}

function update(id, data) {
  return (0, _request.default)({
    url: "/notification/".concat(id, "/update"),
    method: "post",
    data: data
  });
}

function remove(id) {
  return (0, _request.default)({
    url: "/notification/".concat(id, "/delete"),
    method: "post"
  });
}

function notice(id) {
  return (0, _request.default)({
    url: "/notification/".concat(id, "/notice"),
    method: "post"
  });
}