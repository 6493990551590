var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Sản phẩm" } },
            [
              _c("Book", {
                attrs: { removable: false, update: _vm.update },
                on: { "update:success": _vm.updateTable }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Đã xóa" } },
            [
              _c("Book", {
                attrs: { removable: true, update: _vm.update },
                on: { "update:success": _vm.updateTable }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }