"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _configuration = require("@/api/configuration");

var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var Params = {
  HOT_LINE: "Số hotline",
  FACEBOOK: "Link facebook",
  YOUTUBE: "Link youtube",
  WEBSITE: "Website",
  CONTACT_BOOK_PRICE: "Giá kích hoạt sổ liên lạc",
  CONTACT_BOOK_EXPIRE: "Hạn sử dụng sổ liện lạc",
  HEADER_PRINT_TICKET_IMAGE_LINK: "Link hình ảnh phần trên của in phiếu đặt sách",
  FOOTER_PRINT_TICKET_IMAGE_LINK: "Link hình ảnh phần dưới của in phiếu đặt sách",
  NOTE_PRINT_TICKET: "Ghi chú của in phiếu đặt sách"
};
var _default = {
  components: {
    SingleImage: _SingleImage.default
  },
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      Params: Params,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rules: {
        value: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập giá trị"
        }
      },
      tableData: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.$set(this.formData, "value", res.data.path);
    },
    editData: function editData() {
      var _this = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this.loadingButton = true;
          var data = {
            configuration: _this.formData
          };

          _configuration.configApi.update(_this.formData.id, data).then(function () {
            _this.$message({
              type: "success",
              message: "Cập nhật thành công"
            });

            _this.dialogVisible = false;

            _this.getData();
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.dialogStatus = "create";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["form"].resetFields();

        _this2.formData = {};
      });
    },
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, item);
    },
    getData: function getData() {
      var _this3 = this;

      this.isLoading = true;

      _configuration.configApi.findAll().then(function (res) {
        _this3.tableData = res.data.slice(4);
      }).finally(function () {
        _this3.isLoading = false;
      });
    }
  }
};
exports.default = _default;