var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-signup-container" }, [
    _c(
      "div",
      {
        staticClass: "sign-btn",
        on: {
          click: function($event) {
            return _vm.wechatHandleClick("wechat")
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "wx-svg-container" },
          [
            _c("svg-icon", {
              staticClass: "icon",
              attrs: { "icon-class": "wechat" }
            })
          ],
          1
        ),
        _vm._v(" WeChat ")
      ]
    ),
    _c(
      "div",
      {
        staticClass: "sign-btn",
        on: {
          click: function($event) {
            return _vm.tencentHandleClick("tencent")
          }
        }
      },
      [
        _c(
          "span",
          { staticClass: "qq-svg-container" },
          [
            _c("svg-icon", {
              staticClass: "icon",
              attrs: { "icon-class": "qq" }
            })
          ],
          1
        ),
        _vm._v(" QQ ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }