var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            "margin-bottom": "16px"
          }
        },
        [
          !_vm.removable &&
          !_vm.isPartner &&
          _vm.$checkAction("book-index-update")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v(" Thêm mới ")]
              )
            : _vm._e(),
          !_vm.removable &&
          !_vm.isPartner &&
          _vm.$checkAction("book-index-update")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-upload2" },
                  on: { click: _vm.handleImport }
                },
                [_vm._v(" Nhập sách hàng loạt ")]
              )
            : _vm._e(),
          _vm.removable &&
          _vm.selections.length &&
          _vm.$checkAction("book-index-update")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-refresh",
                    loading: _vm.loadingDelete
                  },
                  on: { click: _vm.handleRestores }
                },
                [_vm._v(" Khôi phục ")]
              )
            : _vm._e(),
          _vm.selections.length && _vm.$checkAction("book-index-update")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "danger",
                    icon: "el-icon-delete",
                    loading: _vm.loadingDelete
                  },
                  on: {
                    click: function($event) {
                      _vm.removable
                        ? _vm.handleDeleteComplete()
                        : _vm.handleDelete()
                    }
                  }
                },
                [_vm._v(" Xóa ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "Quản lý sách", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tên, mã sản phẩm, tác giả")
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "prefix-icon": "el-icon-search",
                          placeholder: "Tìm tên,mã sản phẩm, tác giả ..."
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getData($event)
                          }
                        },
                        model: {
                          value: _vm.listQuery.search,
                          callback: function($$v) {
                            _vm.$set(_vm.listQuery, "search", $$v)
                          },
                          expression: "listQuery.search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Danh mục")]),
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "danh mục" },
                          on: { change: _vm.getData },
                          model: {
                            value: _vm.listQuery.categoryId,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "categoryId", $$v)
                            },
                            expression: "listQuery.categoryId"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: undefined }
                          }),
                          _vm._l(_vm.categories, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.id,
                                staticStyle: {
                                  overflow: "unset !important",
                                  "max-width": "500px"
                                },
                                attrs: {
                                  label: _vm.renderBreadcrumb(item),
                                  value: item.id
                                }
                              },
                              [
                                _c("p", { staticStyle: { margin: "0" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.renderBreadcrumb(item)) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Nổi bật")]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Nổi bật", filterable: "" },
                          model: {
                            value: _vm.listQuery.isHighlight,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "isHighlight", $$v)
                            },
                            expression: "listQuery.isHighlight"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null }
                          }),
                          _c("el-option", {
                            attrs: { label: "Có", value: true }
                          }),
                          _c("el-option", {
                            attrs: { label: "Không", value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Cấu hình hoa hồng CTV")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          on: { change: _vm.fetchTableData },
                          model: {
                            value: _vm.listQuery.isCommission,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "isCommission", $$v)
                            },
                            expression: "listQuery.isCommission"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Có cấu hình", value: true }
                          }),
                          _c("el-option", {
                            attrs: { label: "Không cấu hình", value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Nổi bật cho CTV")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Nổi bật", filterable: "" },
                          on: { change: _vm.fetchTableData },
                          model: {
                            value: _vm.listQuery.isHighlightRef,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "isHighlightRef", $$v)
                            },
                            expression: "listQuery.isHighlightRef"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null }
                          }),
                          _c("el-option", {
                            attrs: { label: "Có", value: true }
                          }),
                          _c("el-option", {
                            attrs: { label: "Không", value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Loại")]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Loại", filterable: "" },
                          model: {
                            value: _vm.listQuery.type,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "type", $$v)
                            },
                            expression: "listQuery.type"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null }
                          }),
                          _vm._l(_vm.BookType, function(item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: _vm.BookTypeTrans[item],
                                value: item
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "filter-item" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tình trạng")
                      ]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Tình trạng", filterable: "" },
                          model: {
                            value: _vm.listQuery.isOutOfStock,
                            callback: function($$v) {
                              _vm.$set(_vm.listQuery, "isOutOfStock", $$v)
                            },
                            expression: "listQuery.isOutOfStock"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null }
                          }),
                          _c("el-option", {
                            attrs: { label: "Hết hàng", value: true }
                          }),
                          _c("el-option", {
                            attrs: { label: "Còn hàng", value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { transform: "translateY(14px)" },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: {
                        click: function($event) {
                          return _vm.getData()
                        }
                      }
                    },
                    [_vm._v(" Tìm ")]
                  ),
                  !_vm.removable
                    ? _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { transform: "translateY(14px)" },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-download",
                            loading: _vm.loadingExcel
                          },
                          on: { click: _vm.exportExcel }
                        },
                        [_vm._v(" Xuất excel ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._m(0),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getData
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "technician-table",
          staticStyle: { "margin-top": "16px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "row-class-name": _vm.tableRowClassName
          },
          on: { "selection-change": _vm.handleSelectBook }
        },
        [
          _c("el-table-column", {
            attrs: {
              "class-name": "text-center",
              type: "selection",
              width: "50"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "Hình ảnh",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "thumbnail-box" }, [
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: _vm.$baseUrlMedia + row.thumbnail,
                          alt: ""
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "name", label: "Sản phẩm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Tên:")]),
                        _c("span", [_vm._v(_vm._s(row.name))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Mã:")]),
                        _c("span", [_vm._v(_vm._s(row.code))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Kiotviet ID:")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.kvId))])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Danh mục", prop: "category.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.category.parent &&
                            row.category.parent.name + " / "
                        ) +
                        " " +
                        _vm._s(row.category && row.category.name) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Loại:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.BookTypeTrans[row.type]))
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Giá", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Giá gốc:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.originPrice))
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Giá sau khuyến mãi:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.finalPrice))
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Phần trăm hoa hồng cấp 1: ")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(row.refCommissionPercent) + "%")
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Phần trăm hoa hồng cấp 2: ")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(row.childRefCommissionPercent) + "%")
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          !_vm.removable && !_vm.isPartner
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Nổi bật" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          !row.isHighlight
                            ? _c("el-button", {
                                attrs: {
                                  icon: "el-icon-star-off",
                                  type: "primary",
                                  size: "mini",
                                  loading: row.loadingStar
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeHightlight(row)
                                  }
                                }
                              })
                            : _c("el-button", {
                                staticStyle: { color: "yellow" },
                                attrs: {
                                  icon: "el-icon-star-on",
                                  type: "warning",
                                  size: "mini",
                                  loading: row.loadingStar
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeHightlight(row)
                                  }
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3100128193
                )
              })
            : _vm._e(),
          !_vm.removable && !_vm.isPartner
            ? _c("el-table-column", {
                attrs: { align: "center", label: "Nổi bật cho CTV" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          !row.isHighlightRef
                            ? _c("el-button", {
                                attrs: {
                                  icon: "el-icon-star-off",
                                  type: "primary",
                                  size: "mini",
                                  loading: row.loadingStarRef
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeHightlightRef(row)
                                  }
                                }
                              })
                            : _c("el-button", {
                                staticStyle: { color: "yellow" },
                                attrs: {
                                  icon: "el-icon-star-on",
                                  type: "warning",
                                  size: "mini",
                                  loading: row.loadingStarRef
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeHightlightRef(row)
                                  }
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2810912784
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.$formatDate(row.createdAt)))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "100",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Chi tiết",
                          placement: "top",
                          effect: "dark"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-info"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleUpdate(row)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm.removable && _vm.$checkAction("book-index-update")
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Khôi phục",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-refresh",
                                size: "mini",
                                plain: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleRestore(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    !row.isBlock && _vm.$checkAction("book-index-update")
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Xoá",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                size: "mini"
                              },
                              on: {
                                click: function($event) {
                                  _vm.removable
                                    ? _vm.handleRemove(row)
                                    : _vm.handleBlock(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getData
        }
      }),
      _c("BookModal", { ref: "dialog", on: { "submit:success": _vm.getData } }),
      _c("ImportModal", {
        ref: "importModal",
        attrs: {
          grades: _vm.grades,
          covers: _vm.covers,
          authors: _vm.authors,
          publishers: _vm.publishers
        },
        on: { "import:success": _vm.getData }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "note", staticStyle: { margin: "10px 0" } },
      [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("span", {
            staticStyle: {
              width: "25px",
              display: "inline-block",
              background: "#fbea75"
            }
          }),
          _c(
            "span",
            { staticStyle: { "font-size": "13px", "margin-left": "5px" } },
            [_vm._v("Chưa có trên Kiotviet")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }