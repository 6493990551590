"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _Export2Excel = require("@/vendor/Export2Excel");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  props: ["summaryPreOrder"],
  data: function data() {
    return {
      data: [],
      loading: false,
      form: {
        products: []
      }
    };
  },
  watch: {
    summaryPreOrder: {
      handler: function handler(data) {
        var summary = {
          totalPrice: 0
        };

        if (data) {
          data.preOrderDetails.forEach(function (element) {
            element.totalPrice = element.quantity * element.book.finalPrice; // summary

            summary.totalPrice += element.totalPrice;
          });
          this.form.totalPrice = summary.totalPrice;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    exportExcel: function exportExcel() {
      var header = ["Sản phẩm", "Đơn giá", "Số lượng", "Thành tiền"];
      var data = [];
      var total = {
        quantity: 0,
        amount: 0
      };
      this.summaryPreOrder.preOrderDetails.forEach(function (preoder, index) {
        total.quantity += preoder.quantity;
        total.amount += preoder.totalPrice;
        data.push([preoder.book.name, preoder.book.finalPrice, preoder.quantity, preoder.totalPrice]);
      });
      data.push(["Tổng", "", total.quantity, total.amount]);
      (0, _Export2Excel.export_json_to_excel)({
        data: data,
        header: header,
        ws_name: "Dự trù đầu cấp",
        filename: "Excel - Dự trù đầu cấp"
      });
    }
  }
};
exports.default = _default;