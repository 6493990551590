"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MediaControl = _interopRequireDefault(require("./MediaControl"));

var _Media = require("./components/Media.vue");

//
//
//
//
//
//
var _default = {
  components: {
    MediaControl: _MediaControl.default
  },
  data: function data() {
    return {
      MediaType: _Media.MediaType
    };
  }
};
exports.default = _default;