var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.configSystem === "true"
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingImport },
              on: { click: _vm.importRoutesPath }
            },
            [_vm._v(" Import ")]
          )
        : _vm._e(),
      _vm.$devMode
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingImport },
              on: { click: _vm.updateRoutesPath }
            },
            [_vm._v(" Update ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function($event) {
              return _vm.$refs["add-role"].handleAdd()
            }
          }
        },
        [_vm._v(" Thêm quyền ")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.rolesList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "Tên quyền", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "header-center", label: "Mô tả" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.description) + " ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(scope)
                          }
                        }
                      },
                      [_vm._v(" Cập nhật ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            title: "Cập nhật",
            top: "15px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ],
              attrs: {
                model: _vm.role,
                "label-width": "80px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Tên quyền" },
                    model: {
                      value: _vm.role.name,
                      callback: function($$v) {
                        _vm.$set(_vm.role, "name", $$v)
                      },
                      expression: "role.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mô tả" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "Mô tả"
                    },
                    model: {
                      value: _vm.role.description,
                      callback: function($$v) {
                        _vm.$set(_vm.role, "description", $$v)
                      },
                      expression: "role.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Menus" } },
                [
                  _vm.checkStrictly
                    ? _c("el-tree", {
                        ref: "tree",
                        staticClass: "permission-tree",
                        attrs: {
                          "default-expand-all": true,
                          data: _vm.routesData,
                          props: _vm.defaultProps,
                          "default-checked-keys": _vm.defaultCheck,
                          "show-checkbox": "",
                          "node-key": "path"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v(" Đóng ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", loading: _vm.loadingUpdate },
                  on: { click: _vm.confirmRole }
                },
                [_vm._v(" Đồng ý ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("add-role", { ref: "add-role", on: { "add:success": _vm.getRoles } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }