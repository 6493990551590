"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bookApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var bookApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/book',
      params: params
    });
  },
  findDetail: function findDetail(id) {
    return (0, _request.default)({
      url: "/book/".concat(id)
    });
  },
  findAllRecycle: function findAllRecycle(params) {
    return (0, _request.default)({
      url: '/book/recycle',
      params: params
    });
  },
  findAllBooks: function findAllBooks(params) {
    return (0, _request.default)({
      url: '/book/books',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/book',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/book/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/book/".concat(id, "/delete"),
      method: 'post'
    });
  },
  removes: function removes(data) {
    return (0, _request.default)({
      url: "/book/remove",
      method: 'post',
      data: data
    });
  },
  deletes: function deletes(data) {
    return (0, _request.default)({
      url: "/book/delete",
      method: 'post',
      data: data
    });
  },
  restores: function restores(data) {
    return (0, _request.default)({
      url: "/book/restore",
      method: 'post',
      data: data
    });
  }
};
exports.bookApi = bookApi;