"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _address = require("@/api/address");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      listLoading: false,
      loading: false,
      visible: false,
      loadingModal: false,
      data: []
    };
  },
  created: function created() {},
  methods: {
    handleSave: function handleSave(row) {
      var _this = this;

      this.listLoading = true;

      _address.addressApi.updateDistrict(row.id, {
        addressDistrict: {
          feeDelivery: row.tmpFeeDelivery
        }
      }).then(function () {
        _this.$message.success("Cập nhật phí ship thành công");

        _this.$set(row, "isEdit", false);

        _this.$set(row, "feeDelivery", row.tmpFeeDelivery);
      }).finally(function () {
        return _this.listLoading = false;
      });
    },
    handleCancel: function handleCancel(row) {
      this.$set(row, "isEdit", false);
    },
    handleUpdate: function handleUpdate(row) {
      this.$set(row, "isEdit", true);
      this.$set(row, "tmpFeeDelivery", row.feeDelivery);
    },
    handleOpen: function handleOpen(city) {
      var _this2 = this;

      this.visible = true;
      this.listLoading = true;

      _address.addressApi.findDistrict({
        parentCode: city.code
      }).then(function (res) {
        _this2.data = res.data.data;
      }).finally(function () {
        return _this2.listLoading = false;
      });
    }
  }
};
exports.default = _default;