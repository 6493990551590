"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WithdrawStatusTrans = exports.WithdrawStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _WithdrawStatusTrans;

var WithdrawStatus;
exports.WithdrawStatus = WithdrawStatus;

(function (WithdrawStatus) {
  WithdrawStatus["New"] = "NEW";
  WithdrawStatus["Approve"] = "APPROVE";
  WithdrawStatus["Reject"] = "REJECT";
})(WithdrawStatus || (exports.WithdrawStatus = WithdrawStatus = {}));

var WithdrawStatusTrans = (_WithdrawStatusTrans = {}, (0, _defineProperty2.default)(_WithdrawStatusTrans, WithdrawStatus.New, 'Mới'), (0, _defineProperty2.default)(_WithdrawStatusTrans, WithdrawStatus.Approve, 'Đã duyệt'), (0, _defineProperty2.default)(_WithdrawStatusTrans, WithdrawStatus.Reject, 'Bị từ chối'), _WithdrawStatusTrans);
exports.WithdrawStatusTrans = WithdrawStatusTrans;