var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "book-thumbnail" },
    [
      _c(
        "div",
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Thumbnail (Tỷ lệ: 1/1)")
          ]),
          _c("ChooseFromMedia", {
            attrs: {
              height: "150px",
              width: "150px",
              "path-image": _vm.thumbnail
            },
            on: { onChooseMedia: _vm.onChooseMedia }
          })
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Danh sách ảnh")]),
          _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                action: _vm.$baseUrl + "/book/upload",
                "on-preview": _vm.handlePreview,
                "on-remove": _vm.handleRemove,
                "on-success": _vm.uploadListSuccess,
                "file-list": _vm.fileList,
                headers: { token: _vm.token },
                multiple: "",
                "list-type": "picture-card",
                accept: "image/*"
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v(" Click to upload ")
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleChooseMedia($event)
                        }
                      }
                    },
                    [_vm._v(" Kho media ")]
                  ),
                  _vm._v(
                    " jpg/png files với kích thước nhỏ hơn 5 MB (Tỷ lệ: 1/1) "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "Xem ảnh",
            visible: _vm.previewModal,
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.previewModal = $event
            }
          }
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: {
                width: "350px",
                height: "300px",
                "object-fit": "contain"
              },
              attrs: { src: _vm.imageSelected, alt: "" }
            })
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.previewModal = false
                    }
                  }
                },
                [_vm._v("Đóng")]
              )
            ],
            1
          )
        ]
      ),
      _c("ListMediaModal", {
        ref: "media-modal",
        attrs: { multiple: true, "on-choose": _vm.onChooseMediaList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }