"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ListMediaModal = _interopRequireDefault(require("@/views/media/components/ListMediaModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ListMediaModal: _ListMediaModal.default
  },
  props: {
    pathImage: String,
    width: {
      type: String,
      default: "200px"
    },
    height: {
      type: String,
      default: "200px"
    }
  },
  methods: {
    onChooseMedia: function onChooseMedia(data) {
      this.$emit("onChooseMedia", data.thumbnail);
    },
    handleChooseMedia: function handleChooseMedia() {
      this.$refs["media-modal"].handleOpen();
    }
  }
};
exports.default = _default;