"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.app = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/core-js/modules/es6.array.iterator.js");

require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/core-js/modules/es6.promise.js");

require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/core-js/modules/es6.object.assign.js");

require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/plugins/input-mask");

require("@/plugins/OneSignal");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));

var VueGoogleMaps = _interopRequireWildcard(require("vue2-google-maps"));

var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));

var _locale = _interopRequireDefault(require("element-ui/lib/locale"));

require("./icons");

require("./permission");

require("./utils/error-log");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _date = require("@/utils/date");

var _format = require("@/utils/format");

var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));

var filters = _interopRequireWildcard(require("./filters"));

require("@/plugins/FontAwesome");

var _permission2 = require("./utils/permission");

var _utils = require("./utils");

var devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";

_vue.default.component('Pagination', _index2.default); //google map


_vue.default.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_KEY_GOOGLE_MAP,
    libraries: "places",
    region: "VI",
    language: "vi"
  }
}); //


_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN; // const VueInputMask = require("vue-inputmask").default;
// Vue.use(VueInputMask);

_vue.default.component("InputMoney", _InputMoney.default);

_locale.default.use(_vi.default);

_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size

});

_vue.default.use(_vueClipboard.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = devMode;
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.prototype.$checkAction = _permission2.checkAction;
_vue.default.prototype.$urlImg = _utils.urlImage;
_vue.default.config.productionTip = false;
var app = new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
exports.app = app;