"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _SummaryBookTable = _interopRequireDefault(require("./SummaryBookTable.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SummaryBookTable: _SummaryBookTable.default
  },
  props: ["summaryBook"],
  data: function data() {
    return {
      total: {
        quantity: 0,
        amount: 0
      },
      gradeId: undefined,
      dataTable: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    grades: function grades(state) {
      return state.grade.list;
    }
  })),
  created: function created() {
    this.$store.dispatch("grade/fetchList");
  },
  methods: {}
};
exports.default = _default;