"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var orderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/order',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/order',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  kioviet: function kioviet(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/kiotViet"),
      data: data,
      method: 'post'
    });
  },
  trackingModified: function trackingModified(id, params) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/trackingModification"),
      params: params
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/delete"),
      method: 'post'
    });
  },
  status: function status(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/status/update"),
      data: data,
      method: 'post'
    });
  }
};
exports.orderApi = orderApi;