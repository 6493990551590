var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "history-submit" } },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.data,
            border: "",
            fit: "",
            "show-summary": "",
            "summary-method": _vm.getSummaries
          }
        },
        [
          _c("el-table-column", { attrs: { label: "Sách", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Đơn giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.finalPrice)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "SL",
              prop: "quantity",
              align: "right",
              width: "60"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Thành tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatNumberVN(row.quantity * row.finalPrice)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Phòng" } },
            [
              _c("el-table-column", {
                attrs: { label: "SL nộp", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.quantityDivision)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Hoa hồng", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousandFilter")(row.divisionCommission)
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Công ty" } },
            [
              _c("el-table-column", {
                attrs: { label: "SL nộp", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.quantityCompany)
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "Hoa hồng", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("toThousandFilter")(row.companyCommission)
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }