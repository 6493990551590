"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _categoryBook = require("@/api/category-book");

var _vuex = require("vuex");

var _ChooseFromMedia = _interopRequireDefault(require("@/components/Upload/ChooseFromMedia"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    ChooseFromMedia: _ChooseFromMedia.default
  },
  props: {
    levelSelected: {
      type: Number,
      default: null
    },
    queryLevel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      categories: [],
      loading: false,
      form: {},
      visible: false,
      status: "create",
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        schoolId: {
          required: true,
          message: "Vui lòng chọn trường",
          trigger: "submit"
        }
      },
      loadingModal: false,
      schools: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  created: function created() {
    this.fetchCategories();
  },
  methods: {
    fetchCategories: function fetchCategories() {
      var _this = this;

      _categoryBook.categoryBookApi.findAll().then(function (res) {
        _this.categories = res.data.data;
      });
    },
    handleDelete: function handleDelete() {
      var _this2 = this;

      this.$confirm("Danh mục sạch này sẽ bị xoá, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this2.loading = true;

        _categoryBook.categoryBookApi.remove(_this2.form.id).then(function () {
          _this2.$notify.success({
            message: "Xoá thành công",
            title: "Thông báo"
          });

          _this2.$emit("submit:success");

          _this2.visible = false;
        }).finally(function () {
          return _this2.loading = false;
        });
      });
    },
    handleUploadSuccess: function handleUploadSuccess(thumbnail) {
      this.$set(this.form, "thumbnail", thumbnail);
    },
    beforeUpload: function beforeUpload(file) {
      var isJPG = file.type.includes("image");
      var isLt2M = file.size / 1024 / 1024 < 5;
      this.loadingModal = true;

      if (!isJPG) {
        this.$message.error("Image picture must be image format!");
        this.loadingModal = false;
      }

      if (!isLt2M) {
        this.loadingModal = false;
        this.$message.error("Image picture size can not exceed 5MB!");
      }

      return isJPG && isLt2M;
    },
    handleCreate: function handleCreate(parent) {
      this.status = "create";
      this.visible = true;

      if (parent) {
        this.form = {
          parentId: parent.id
        };
      } else {
        this.form = {};
      }
    },
    handleUpdate: function handleUpdate(data) {
      this.form = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data), {}, {
        parentId: data.parent && data.parent.id
      });
      this.status = "update";
      this.visible = true;
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            category: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this3.form), _this3.queryLevel), {}, {
              level: _this3.levelSelected + 1
            })
          };
          _this3.loading = true;

          _categoryBook.categoryBookApi.create(data).then(function () {
            _this3.$notify.success({
              message: "Tạo thành công",
              title: "Thông báo"
            });

            _this3.visible = false;

            _this3.$emit("submit:success");
          }).finally(function () {
            return _this3.loading = false;
          });
        }
      });
    },
    updateData: function updateData() {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var data = {
            category: (0, _objectSpread2.default)({}, _this4.form),
            parentId: _this4.form.parentId
          };
          _this4.loading = true;

          _categoryBook.categoryBookApi.update(_this4.form.id, data).then(function () {
            _this4.$notify.success({
              message: "Cập nhật thành công",
              title: "Thông báo"
            });

            _this4.visible = false;

            _this4.$emit("submit:success");
          }).finally(function () {
            return _this4.loading = false;
          });
        }
      });
    }
  }
};
exports.default = _default2;