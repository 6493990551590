var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Tạo lệnh rút",
        visible: _vm.visible,
        width: "700px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "customerId", label: "Cộng tác viên" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "value-key": "",
                    placeholder: "",
                    remote: "",
                    "remote-method": _vm.fetchCustomers
                  },
                  model: {
                    value: _vm.form.customerId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "customerId", $$v)
                    },
                    expression: "form.customerId"
                  }
                },
                _vm._l(_vm.customers, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.name + " - " + item.phone,
                      value: item.id
                    }
                  })
                }),
                1
              ),
              _vm.form.customerId
                ? _c("div", [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Ví còn: ")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatNumberVN(_vm.customer.balance)))
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Số tiền rút", prop: "amount" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
                  }
                ],
                model: {
                  value: _vm.form.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú", prop: "note" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.note,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.createData }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }