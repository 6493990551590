"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.partnerRegistrationFormApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var partnerRegistrationFormApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/partnerRegistrationForm',
      params: params
    });
  },
  approve: function approve(id) {
    return (0, _request.default)({
      url: "/partnerRegistrationForm/".concat(id, "/approve"),
      method: 'put'
    });
  },
  reject: function reject(id) {
    return (0, _request.default)({
      url: "/partnerRegistrationForm/".concat(id, "/reject"),
      method: 'put'
    });
  }
};
exports.partnerRegistrationFormApi = partnerRegistrationFormApi;