"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dashboardApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var dashboardApi = {
  bookSummary: function bookSummary() {
    return (0, _request.default)({
      url: "/dashboard/book/summary"
    });
  },
  orderSummary: function orderSummary() {
    return (0, _request.default)({
      url: "/dashboard/order/summary"
    });
  },
  schoolSummary: function schoolSummary() {
    return (0, _request.default)({
      url: "/dashboard/school/summary"
    });
  },
  preOrderSummary: function preOrderSummary() {
    return (0, _request.default)({
      url: "/dashboard/preOrder/summary"
    });
  },
  orderDayChart: function orderDayChart(params) {
    return (0, _request.default)({
      url: "/dashboard/order/group/day",
      params: params
    });
  },
  orderMonthChart: function orderMonthChart(params) {
    return (0, _request.default)({
      url: "/dashboard/order/group/month",
      params: params
    });
  },
  bookTop10: function bookTop10() {
    return (0, _request.default)({
      url: "/dashboard/book/top10"
    });
  }
};
exports.dashboardApi = dashboardApi;