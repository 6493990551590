var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Nhập sách hàng loạt(Excel)",
        visible: _vm.visible,
        width: _vm.modalWidth,
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Nhập hàng", name: "import" } },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Lưu ý:")]),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    " Đối với file excel import vào hệ thống phải giống với mẫu 100% "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    ' Danh sách ảnh là các đường link media cách nhau bởi dấu "," ở cột "img" '
                  )
                ]),
                _c("li", [
                  _vm._v(' Loại gồm 2 loại là "single", "combo" ở cột "type" ')
                ]),
                _c("li", [
                  _vm._v(
                    ' Những cột dữ liệu dạng "*Id" phải tuân theo danh sách file excel dưới đây: '
                  )
                ]),
                _c("li", [_vm._v(" publisherId (Nhà xuất bản) ")]),
                _c("li", [_vm._v(" categoryId (Danh mục sách) ")]),
                _c("li", [_vm._v(" coverId (Hình thức) ")]),
                _c("li", [_vm._v(" gradeId (Khối) ")]),
                _c("li", [_vm._v(" authorId (Tác giả) ")])
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadSample }
                },
                [_vm._v(" Tải file mẫu ")]
              ),
              _c("UploadExcel", {
                staticStyle: { "margin-top": "16px" },
                attrs: { "on-success": _vm.handleUploadSuccess }
              })
            ],
            1
          ),
          _vm.data.length
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Kết quả", name: "result" } },
                [
                  _c("ResultImportTable", {
                    attrs: {
                      grades: _vm.grades,
                      authors: _vm.authors,
                      covers: _vm.covers,
                      categories: _vm.categories,
                      publishers: _vm.publishers,
                      data: _vm.data
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _vm.data.length
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.createData }
                },
                [_vm._v("Nhập hàng")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }