var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-button", {
        staticStyle: { "margin-bottom": "10px" },
        attrs: { type: "primary", icon: "el-icon-plus" },
        on: { click: _vm.onPressAdd }
      }),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v(" Danh mục sách ")]),
          _c("el-col", { attrs: { span: 12 } }, [_vm._v(" Hoa hồng ")])
        ],
        1
      ),
      !_vm.details.length
        ? _c(
            "el-row",
            { staticStyle: { "margin-bottom": "10px" }, attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v(" Không có")
                ])
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v(" Không có")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.details, function(item, index) {
        return _c(
          "el-row",
          {
            key: index,
            staticStyle: { "margin-bottom": "8px" },
            attrs: { gutter: 10 }
          },
          [
            _c(
              "el-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { value: item.categoryId, placeholder: "" },
                    on: {
                      change: function($event) {
                        return _vm.handleChangeCateogry(index, $event)
                      }
                    }
                  },
                  _vm._l(_vm.categories, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("input-money", {
                    staticStyle: { height: "32px" },
                    model: {
                      value: item.value,
                      callback: function($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value"
                    }
                  }),
                  _c("el-button", {
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      type: "danger",
                      size: "mini",
                      icon: "el-icon-delete"
                    },
                    on: {
                      click: function() {
                        return _vm.onPressDelete(index)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }