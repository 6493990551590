var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Chuyển lớp",
        visible: _vm.visible,
        width: "500px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Lớp", prop: "classroomId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.form.classroomId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "classroomId", $$v)
                    },
                    expression: "form.classroomId"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "Ra trường", value: 0 } }),
                  _vm._l(_vm.classrooms, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.transfer }
            },
            [_vm._v("Chuyển lớp")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }