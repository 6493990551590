var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.status == "create" ? "Thêm giáo viên" : "Cập nhật giáo viên",
            visible: _vm.visible,
            width: "500px",
            top: "30px",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "top",
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.form.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Trường", prop: "schoolId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                disabled: !!_vm.schoolId,
                                placeholder: ""
                              },
                              on: { change: _vm.handleChangeSchool },
                              model: {
                                value: _vm.form.schoolId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "schoolId", $$v)
                                },
                                expression: "form.schoolId"
                              }
                            },
                            _vm._l(_vm.schools, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.name +
                                    " (" +
                                    item.addressCity.name +
                                    ")",
                                  value: item.id
                                }
                              })
                            }),
                            1
                          ),
                          _c("el-button", {
                            staticClass: "btn-add-in-select",
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-plus"
                            },
                            on: { click: _vm.handleCreateSchool }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Chủ nhiệm" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", placeholder: "" },
                              model: {
                                value: _vm.form.classroomId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "classroomId", $$v)
                                },
                                expression: "form.classroomId"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Không chủ nhiệm", value: 0 }
                              }),
                              _vm._l(_vm.classrooms, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: "" + item.name,
                                    value: item.id
                                  }
                                })
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu đầu tiên" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              value: _vm.form.isChangedDefaultPassword
                                ? "Đã đổi mật khẩu"
                                : _vm.form.firstPassword
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "text-center",
                  attrs: { label: "Avatar", prop: "avatar" }
                },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        name: "file",
                        drag: "",
                        "before-upload": _vm.beforeUpload,
                        action: _vm.$baseUrl + "/teacher/upload",
                        "on-success": _vm.handleUploadSuccess,
                        "show-file-list": false,
                        headers: { token: _vm.token }
                      }
                    },
                    [
                      !_vm.form.avatar
                        ? [
                            _c("i", { staticClass: "el-icon-upload" }),
                            _c("div", { staticClass: "el-upload__text" }, [
                              _vm._v(" Kéo thả vào đây hoặc "),
                              _c("em", [_vm._v("click vào để upload banner")])
                            ])
                          ]
                        : _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              "object-fit": "contain"
                            },
                            attrs: {
                              src: _vm.$baseUrlMedia + _vm.form.avatar,
                              alt: ""
                            }
                          })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    }
                  }
                },
                [_vm._v("Đồng ý")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SchoolModal", {
        ref: "school-modal",
        on: { "submit:success": _vm.fetchSchools }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }