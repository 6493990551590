var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.status == "create" ? "Thêm chiến dịch" : "Cập nhật chiến dịch",
        visible: _vm.visible,
        width: "600px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ngày kết thúc của học sinh",
                        prop: "closeCustomerAt"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "timestamp",
                          format: "dd/MM/yyyy",
                          type: "date"
                        },
                        model: {
                          value: _vm.form.closeCustomerAt,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "closeCustomerAt", $$v)
                          },
                          expression: "form.closeCustomerAt"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ngày kết thúc của giáo viên",
                        prop: "closeTeacherAt"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "timestamp",
                          format: "dd/MM/yyyy",
                          type: "date"
                        },
                        model: {
                          value: _vm.form.closeTeacherAt,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "closeTeacherAt", $$v)
                          },
                          expression: "form.closeTeacherAt"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ngày kết thúc đợt đặt sách",
                        prop: "closeTeacherAt"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "timestamp",
                          format: "dd/MM/yyyy",
                          type: "date"
                        },
                        model: {
                          value: _vm.form.closeAt,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "closeAt", $$v)
                          },
                          expression: "form.closeAt"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("span", [_vm._v("Danh sách khối ")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", size: "mini" },
              on: { click: _vm.handleAddGrade }
            },
            [_c("span", [_vm._v("Thêm khối")])]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          ),
          _vm._l(_vm.details, function(item, index) {
            return _c(
              "el-row",
              { key: index, attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "Khối" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              position: "absolute",
                              "z-index": "1",
                              top: "-35px",
                              right: "48px"
                            },
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-plus"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleAddDetailGrade(item)
                              }
                            }
                          },
                          [_vm._v("Thêm sách ")]
                        ),
                        _c("el-button", {
                          staticStyle: {
                            position: "absolute",
                            "z-index": "1",
                            top: "-35px",
                            right: "0"
                          },
                          attrs: {
                            type: "danger",
                            size: "mini",
                            icon: "el-icon-delete",
                            disabled: _vm.details.length == 1
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(index)
                            }
                          }
                        }),
                        _c(
                          "el-select",
                          {
                            attrs: { value: item.gradeId, placeholder: "" },
                            on: {
                              change: function($event) {
                                return _vm.handleChangeGrade(index, $event)
                              }
                            }
                          },
                          _vm._l(_vm.grades, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      ),
      _c("StrategyOrderGradesModal", { ref: "dialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }