"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.chatTeacherApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var chatTeacherApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/chatAdminTeacher/teachers",
      params: params
    });
  },
  findOne: function findOne(id, params) {
    return (0, _request.default)({
      url: "/chatAdminTeacher/teachers/".concat(id),
      params: params
    });
  },
  chat: function chat(id, data) {
    return (0, _request.default)({
      url: "/chatAdminTeacher/teachers/".concat(id),
      method: "post",
      data: data
    });
  },
  deleteConversation: function deleteConversation(id) {
    return (0, _request.default)({
      url: "/chatAdminTeacher/teachers/".concat(id, "/delete"),
      method: "post"
    });
  }
};
exports.chatTeacherApi = chatTeacherApi;