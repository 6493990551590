var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "panel-item",
      on: {
        click: function($event) {
          return _vm.onEdit(_vm.banner)
        }
      }
    },
    [
      _c("div", { staticClass: "panel-box" }, [
        _vm.banner.news || _vm.banner.promotion || _vm.isOpenApp
          ? _c("span", { staticClass: "label-mask" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.banner.news && "Tin tức") +
                  " " +
                  _vm._s(_vm.banner.promotion && "Khuyến mãi") +
                  " " +
                  _vm._s(_vm.isOpenApp && "App") +
                  " "
              )
            ])
          : _vm._e(),
        _c("img", {
          staticClass: "image",
          attrs: { src: _vm.$baseUrlMedia + _vm.banner.thumbnail, alt: "" }
        }),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "12px",
              display: "block",
              "margin-top": "8px"
            }
          },
          [_vm._v("Vị trí: " + _vm._s(_vm.banner.position))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }