var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "light-box-shadow summary-box" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 120 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "summmary-item" }, [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Tổng số sách: ")
              ]),
              _c("span", { staticClass: "value primary-color" }, [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.summary.quantityBook)) +
                    " quyển"
                )
              ])
            ]),
            _c("div", { staticClass: "summmary-item" }, [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Tổng tiền: ")
              ]),
              _c("span", { staticClass: "value error-color" }, [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.summary.amountTotal)) +
                    " đ"
                )
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "summmary-item" }, [
              _c("label", { staticClass: "label", attrs: { for: "" } }, [
                _vm._v("Tổng hoa hồng: ")
              ]),
              _c("span", { staticClass: "value yellow-color" }, [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.totalCommission)) + " đ"
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }