"use strict";

var _interopRequireWildcard = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var store = _interopRequireWildcard(require("@/api/store"));

var _Food = _interopRequireDefault(require("./Food"));

var _MapStore = _interopRequireDefault(require("./MapStore"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Food: _Food.default,
    MapStore: _MapStore.default
  },
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "info":
          this.dialogWidth = "500px";
          break;

        default:
          this.dialogWidth = "1300px";
          break;
      }
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!value) {
        callback(new Error("Vui lòng nhập email"));
      } else if (!validEmail(value)) {
        callback(new Error("Email không hợp lệ"));
      } else {
        callback();
      }
    };

    return {
      dialogWidth: "500px",
      tabName: "info",
      STORE_TYPE: enumConst.STORE_TYPE,
      typeVehicles: [],
      dialogLoading: false,
      marker: {},
      tempData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        address: {
          required: true,
          message: "Vui lòng nhập địa chỉ",
          trigger: "submit"
        },
        type: {
          required: true,
          trigger: "submit",
          message: "Vui lòng chọn loại cửa hàng"
        },
        openTime: {
          required: true,
          message: "Vui lòng chọn giờ mở cửa",
          trigger: "submit"
        },
        closeTime: {
          required: true,
          message: "Vui lòng chọn giờ đóng cửa",
          trigger: "submit"
        }
      }
    };
  },
  methods: {
    setAddress: function setAddress(address) {
      this.tempData.address = address;
    },
    setMarker: function setMarker(marker) {
      this.tempData.lat = marker.lat;
      this.tempData.long = marker.lng;
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      this.dialogLoading = false;
      this.$set(this.tempData, "thumbnail", res.data.path);
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Avatar picture must be image format!");
      }

      return isJPG;
    },
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            store: _this.tempData
          };
          _this.loadingButton = true;
          store.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật cửa hàng thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)({}, data);
        _this2.marker = {
          lat: data.lat,
          lng: data.long
        };
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tabName = "info";
      this.$nextTick(function () {
        _this3.$refs["dataForm"].resetFields();

        _this3.marker = {};
        _this3.tempData = {
          type: "",
          openTime: "",
          closeTime: "",
          lat: 0,
          long: 0
        };
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            store: _this4.tempData
          };
          _this4.loadingButton = true;
          store.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo cửa hàng thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].clearValidate();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    }
  }
};
exports.default = _default;