"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.authApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var authApi = {
  update: function update(data) {
    return (0, _request.default)({
      url: "/auth/profile",
      method: "post",
      data: data
    });
  }
};
exports.authApi = authApi;