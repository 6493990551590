"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackingCustomerApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var trackingCustomerApi = {
  report: function report(params) {
    return (0, _request.default)({
      url: "/customerTracking/report",
      params: params
    });
  }
};
exports.trackingCustomerApi = trackingCustomerApi;