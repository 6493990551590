var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-panel" }, [
    _c("div", { staticClass: "left-wrapper" }, [
      _c("div", { staticClass: "card-panel-text" }, [_vm._v(" Đặt sách ")]),
      _c(
        "div",
        { staticClass: "card-panel-icon-wrapper icon-money" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: "dolly-flatbed", size: "3x" }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "card-panel-description" }, [
      _c("div", { staticStyle: { "justify-content": "center" } }, [
        _c(
          "span",
          {
            staticClass: "primary-color",
            staticStyle: { "font-size": "18px", transform: "translateY(-15px)" }
          },
          [_vm._v(_vm._s(_vm.strategyOrder.name))]
        )
      ]),
      _c(
        "div",
        [
          _c("span", [_vm._v("Tổng sách:")]),
          _c("count-to", {
            staticClass: "card-panel-num primary-color",
            attrs: {
              "start-val": 0,
              "end-val": _vm.summary.quantityBook,
              duration: 3200
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Tổng tiền:")]),
          _c("count-to", {
            staticClass: "card-panel-num error-color",
            attrs: {
              "start-val": 0,
              "end-val": _vm.summary.amountTotal,
              duration: 3200
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Chiết khấu cho trường:")]),
          _c("count-to", {
            staticClass: "card-panel-num yellow-color",
            attrs: {
              "start-val": 0,
              "end-val": _vm.commissionSchool,
              duration: 3200
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Chiết khấu cho phòng:")]),
          _c("count-to", {
            staticClass: "card-panel-num yellow-color",
            attrs: {
              "start-val": 0,
              "end-val": _vm.commissionDivision,
              duration: 3200
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span", [_vm._v("Tổng chiết khấu:")]),
          _c("count-to", {
            staticClass: "card-panel-num error-color",
            attrs: {
              "start-val": 0,
              "end-val": _vm.commissionDivision + _vm.commissionSchool,
              duration: 3200
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }