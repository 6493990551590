"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));

var _BannerWebDialog = _interopRequireDefault(require("./components/BannerWebDialog.vue"));

var _BannerItem = _interopRequireDefault(require("../banner/components/BannerItem.vue"));

var _bannerWeb = require("@/api/banner-web");

var _default = {
  components: {
    Pagination: _index.default,
    BannerDialog: _BannerWebDialog.default,
    BannerItem: _BannerItem.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleCreate();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this = this;

      this.$confirm("Bannernày sẽ được hiển thị, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          bannerWeb: {
            isShow: false
          }
        };

        _bannerWeb.bannerWebApi.update(row.id, data).then(function () {
          row.isShow = false;

          _this.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;

      this.$confirm("Banner này sẽ bị ẩn, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          bannerWeb: {
            isShow: true
          }
        };

        _bannerWeb.bannerWebApi.update(row.id, data).then(function () {
          row.isShow = true;

          _this2.$message({
            type: "success",
            message: "Thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;

      this.listLoading = true;

      _bannerWeb.bannerWebApi.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.bannerWebs;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    }
  }
};
exports.default = _default;