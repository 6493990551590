var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "1000px",
        top: "30px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("span", [
                _vm._v(
                  "Chi tiết (" +
                    _vm._s(
                      _vm.data.strategyOrder && _vm.data.strategyOrder.name
                    ) +
                    ")"
                )
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Tổng quan", name: "general" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "8px" },
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.handleExport }
                },
                [_vm._v("Xuất excel")]
              ),
              _c("SummaryDivision", {
                attrs: {
                  "total-commission": _vm.totalCommission,
                  summary: _vm.data.summary
                }
              }),
              _c("HistorySubmitDivision", {
                staticStyle: { "margin-top": "16px" },
                attrs: { "summary-book": _vm.divisionSubmitPreOrderDetails }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Khối", name: "grade" } },
            [
              _c("SummaryGrade", {
                attrs: { "summary-grade": _vm.data.grades }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }