"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerType = void 0;
var CustomerType;
exports.CustomerType = CustomerType;

(function (CustomerType) {
  CustomerType["Student"] = "STUDENT";
  CustomerType["Normal"] = "NORMAL";
})(CustomerType || (exports.CustomerType = CustomerType = {}));