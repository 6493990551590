var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "promotion" } },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
            [_vm._v(" Thêm khuyến mãi ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "STT" } }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "Promotion",
              width: "250",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(row.name))
                        ])
                      ]),
                      _c("img", {
                        staticClass: "image",
                        attrs: {
                          src: _vm.baseUrlMedia + row.thumbnail,
                          alt: ""
                        }
                      })
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "8px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tiêu đề: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.title))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Code: ")]),
                        _c("span", [_vm._v(_vm._s(row.code))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Giảm giá: ")
                        ]),
                        _c(
                          "span",
                          [
                            row.type == "PERCENT"
                              ? [_vm._v(" " + _vm._s(row.value) + "% ")]
                              : _vm._e(),
                            row.type == "PRICE"
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatCurrency(row.value)) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "145", label: "Thời gian" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "8px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Bắt đầu: ")
                        ]),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.formatDate(row.startAt)) + " "
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Kết thúc: ")
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.formatDate(row.endAt)) + " ")
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "250", label: "Điều kiện" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticClass: "condition-list",
                        staticStyle: { "padding-left": "10px" }
                      },
                      [
                        _c("li", [
                          _vm._v(
                            " Giá trị đơn hàng tối thiểu: " +
                              _vm._s(
                                _vm.formatCurrency(row.minMoneyTotalCanApprove)
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "0" },
                        attrs: {
                          type: "danger",
                          size: "mini",
                          loading: row.loadingDelete
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(row)
                          }
                        }
                      },
                      [_vm._v(" Xoá ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update"
                ? "Cập nhật khuyến mãi"
                : "Thêm khuyến mãi",
            visible: _vm.dialogVisible,
            width: "600px",
            top: "15px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading"
                }
              ]
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Tiêu đề", prop: "title" }
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "title", $$v)
                                          },
                                          expression: "formData.title"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Code", prop: "code" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.code,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "code", $$v)
                                          },
                                          expression: "formData.code"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        label: "Ngày bắt đầu",
                                        prop: "startAt"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day"
                                        },
                                        model: {
                                          value: _vm.formData.startAt,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "startAt",
                                              $$v
                                            )
                                          },
                                          expression: "formData.startAt"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Ngày kết thúc",
                                        prop: "endAt"
                                      }
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day"
                                        },
                                        model: {
                                          value: _vm.formData.endAt,
                                          callback: function($$v) {
                                            _vm.$set(_vm.formData, "endAt", $$v)
                                          },
                                          expression: "formData.endAt"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.promotionFor
                                ? [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label:
                                                "Giá trị đơn hàng thấp nhất(VNĐ)",
                                              prop: "minMoneyTotalCanApprove"
                                            }
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formData
                                                      .minMoneyTotalCanApprove,
                                                  expression:
                                                    "formData.minMoneyTotalCanApprove"
                                                },
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: {
                                                    alias: "decimal",
                                                    groupSeparator: ".",
                                                    rightAlign: false,
                                                    autoGroup: true,
                                                    repeat: 20,
                                                    autoUnmask: true,
                                                    allowMinus: false
                                                  },
                                                  expression:
                                                    "{\n                        alias: 'decimal',\n                        groupSeparator: '.',\n                        rightAlign: false,\n                        autoGroup: true,\n                        repeat: 20,\n                        autoUnmask: true,\n                        allowMinus: false,\n                      }"
                                                }
                                              ],
                                              attrs: {
                                                placeholder:
                                                  "Để trống sẽ không áp dụng",
                                                type: "text"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formData
                                                    .minMoneyTotalCanApprove
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minMoneyTotalCanApprove",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Loại khuyến mãi",
                                        prop: "type"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: _vm.formData.type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "formData.type"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo giá",
                                              value: "PRICE"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo phần trăm",
                                              value: "PERCENT"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giá trị khuyến mãi",
                                        prop: "value"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.formData.value,
                                            expression: "formData.value"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false
                                            },
                                            expression:
                                              "{\n                      alias: 'decimal',\n                      groupSeparator: '.',\n                      rightAlign: false,\n                      autoGroup: true,\n                      repeat: 20,\n                      autoUnmask: true,\n                      allowMinus: false,\n                    }"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.formData.value },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.formData,
                                              "value",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Thumbnail (Tỷ lệ: 3/2)" }
                                    },
                                    [
                                      _c("ChooseFromMedia", {
                                        attrs: {
                                          height: "150px",
                                          width: "150px",
                                          "path-image": _vm.thumbnail
                                        },
                                        on: { onChooseMedia: _vm.onChooseMedia }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Image (Tỷ lệ: 2/1)" } },
                                    [
                                      _c("ChooseFromMedia", {
                                        attrs: {
                                          height: "150px",
                                          width: "150px",
                                          "path-image": _vm.image
                                        },
                                        on: {
                                          onChooseMedia: _vm.onChooseMediaImage
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    }
                  }
                },
                [_vm._v("Đồng ý")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }