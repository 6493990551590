var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingModal,
          expression: "loadingModal"
        }
      ],
      attrs: {
        "close-on-click-modal": false,
        title: _vm.status == "create" ? "Thêm media" : "Cập nhật media",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Ảnh", prop: "thumbnail" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    action: _vm.$baseUrl + "/media/upload",
                    "file-list": _vm.fileList,
                    "on-success": _vm.uploadListSuccess,
                    "on-remove": _vm.handleRemove,
                    "on-error": _vm.handleError,
                    headers: { token: _vm.token },
                    "list-type": "picture-card",
                    accept: "image/*",
                    multiple: ""
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v(" Click to upload ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [
                      _vm._v(" Có thể upload được nhiều ảnh cùng lúc! "),
                      _c("br"),
                      _vm._v(" Tên file chỉ bao gồm "),
                      _c("b", [
                        _vm._v(
                          "(kí tự thường, kí tự in hoa, chữ số, '_' , '-')"
                        )
                      ]),
                      _c("br"),
                      _vm._v(
                        " Lưu ý không bao gồm các ký tự đặc biệt (*,&,...) "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }