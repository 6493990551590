var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Chọn ngày")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "To",
                format: "dd/MM/yyyy",
                "value-format": "yyyy-MM-dd",
                "start-placeholder": "Ngày bắt đầu",
                "end-placeholder": "Ngày kết thúc"
              },
              on: { change: _vm.handleQuery },
              model: {
                value: _vm.date,
                callback: function($$v) {
                  _vm.date = $$v
                },
                expression: "date"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Mã đơn")]),
            _c("el-input", {
              staticStyle: { width: "300px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Tìm mã đơn"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleQuery($event)
                }
              },
              model: {
                value: _vm.listQuery.search,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái đơn")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "Trạng thái đơn" },
                on: { change: _vm.handleQuery },
                model: {
                  value: _vm.listQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status"
                }
              },
              [
                _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                _vm._l(_vm.OrderStatus, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: key }
                  })
                })
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Tỉnh thành" },
                    on: { change: _vm.fetchDistricts },
                    model: {
                      value: _vm.listQuery.cityId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "cityId", $$v)
                      },
                      expression: "listQuery.cityId"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { value: null, label: "Tất cả" }
                    }),
                    _vm._l(_vm.cities, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id }
                      })
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Quận huyện")]),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Quận huyện" },
                    on: { change: _vm.fetchWards },
                    model: {
                      value: _vm.listQuery.districtId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "districtId", $$v)
                      },
                      expression: "listQuery.districtId"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { value: null, label: "Tất cả" }
                    }),
                    _vm._l(_vm.districts, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id }
                      })
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Phường xã")]),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Phường xã" },
                    on: { change: _vm.fetchTableData },
                    model: {
                      value: _vm.listQuery.wardId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "wardId", $$v)
                      },
                      expression: "listQuery.wardId"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { value: null, label: "Tất cả" }
                    }),
                    _vm._l(_vm.wards, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id }
                      })
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { transform: "translateY(10px)" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.fetchTableData }
              },
              [_vm._v(" Tìm ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { transform: "translateY(10px)" },
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel
                },
                on: { click: _vm.exportExcel }
              },
              [_vm._v(" Xuất excel ")]
            )
          ],
          1
        )
      ]),
      _vm._m(0),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "technician-table",
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "row-class-name": _vm.tableRowClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { prop: "code", width: "180", label: "Mã đơn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(_vm._s(row.code))
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: { type: _vm.renderTypeStatus(row.status) }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.OrderStatusMerge[row.status] ||
                                      "Không xác định"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "8px" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled:
                                  row.status == "CANCEL" || row.status == "4",
                                value: row.status,
                                placeholder: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChangeStatus($event, row)
                                }
                              }
                            },
                            _vm._l(_vm.OrderStatus, function(item, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: item, value: key }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Mã Kiotviet" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticStyle: { "padding-left": "10px" } },
                      [
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Kioviet Code: ")
                          ]),
                          _c("span", [_vm._v(_vm._s(row.kvCode))])
                        ]),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Kioviet Id: ")
                          ]),
                          _c("span", [_vm._v(_vm._s(row.kvId))])
                        ]),
                        row.kvCode == "" && !row.kvId
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Gửi lên Kiotviet",
                                  placement: "top",
                                  effect: "dark"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "success",
                                    size: "mini",
                                    icon: "el-icon-s-order"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleSendKiotviet(row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "10px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Họ tên: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.name))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Số điện thoại: ")
                        ]),
                        _c("span", [_vm._v(_vm._s(row.phone))])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Địa chỉ: ")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.address) +
                              ", " +
                              _vm._s(row.addressWard.nameWithType) +
                              ", " +
                              _vm._s(row.addressDistrict.nameWithType) +
                              ", " +
                              _vm._s(row.addressCity.nameWithType)
                          )
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Tổng tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "detail-order" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng số lượng:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(
                                _vm.getTotalQuantity(row)
                              )
                            )
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng tiền hàng:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.moneyTotal))
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Giảm giá:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.moneyDiscount)
                            )
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tiền vận chuyển:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toThousandFilter")(row.moneyDistance)
                            )
                          )
                        ])
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tổng tiền:")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("toThousandFilter")(row.moneyFinal))
                          )
                        ])
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Thanh toán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v(
                          _vm._s(
                            row.paymentType == "CASH" ? "Tiền mặt" : "VNPay"
                          )
                        )
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.renderPaymentStatus(row.paymentStatus)
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.PaymentStatus[row.paymentStatus] ||
                                      "Không xác định"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "8px" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              attrs: {
                                disabled: row.paymentType != "CASH",
                                value: row.paymentStatus,
                                placeholder: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleChangePaymentStatus(
                                    $event,
                                    row
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.PaymentStatus, function(item, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: item, value: key }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$formatDateTime(row.createdAt)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "80",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-direction": "column",
                          display: "flex",
                          "align-items": "flex-start"
                        }
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Chi tiết đơn",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-info"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleView(row)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Cập nhật đơn",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleUpdate(row)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Xuất excel",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-download"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exportExcelDetail(row)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "action-tooltip",
                            attrs: {
                              content: "Lịch sử chỉnh sửa",
                              placement: "top",
                              effect: "dark"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "warning",
                                size: "mini",
                                icon: "el-icon-s-operation"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleTrackingModified(row)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchTableData
        }
      }),
      _c("OrderDetailModal", { ref: "dialog" }),
      _c("OrderModal", {
        ref: "order-modal",
        on: { "submit:success": _vm.fetchTableData }
      }),
      _c("tracking-modified-order-modal", { ref: "TrackingModifiedOrderModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "note", staticStyle: { margin: "10px 0" } },
      [
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("span", {
            staticStyle: {
              width: "25px",
              display: "inline-block",
              background: "#fbea75"
            }
          }),
          _c(
            "span",
            { staticStyle: { "font-size": "13px", "margin-left": "5px" } },
            [_vm._v("Chưa có trên Kiotviet")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }