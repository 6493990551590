"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolContactBookApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var schoolContactBookApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/schoolContactBookTransaction",
      params: params
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/schoolContactBookTransaction/".concat(id, "/delete"),
      method: "post"
    });
  }
};
exports.schoolContactBookApi = schoolContactBookApi;