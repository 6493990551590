var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat" },
    [
      _c("chat-window", {
        attrs: {
          height: _vm.heightChat,
          "current-user-id": 0,
          rooms: _vm.rooms,
          "loading-rooms": _vm.loadingRoom,
          messages: _vm.messages,
          "rooms-loaded": _vm.roomLoaded,
          "show-files": false,
          "show-audio": false,
          "show-add-room": false,
          "show-emojis": false,
          "show-reaction-emojis": false,
          "messages-loaded": _vm.messagesLoaded,
          "message-actions": [],
          "room-actions": _vm.roomActions,
          "room-id": _vm.roomSelected.id
        },
        on: {
          "room-action-handler": _vm.handleActionHandler,
          "send-message": _vm.sendMessage,
          "fetch-more-rooms": _vm.fetchMoreRooms,
          "fetch-messages": _vm.fetchMoreMessage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }