"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Media = require("./Media.vue");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    MediaControl: function MediaControl() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../MediaControl"));
      });
    }
  },
  props: {
    onChoose: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selected: null,
      MediaType: _Media.MediaType,
      listQuery: {
        limit: 20,
        page: 1
      },
      visible: false,
      mediaListSelected: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    medias: function medias(state) {
      return state.media.list;
    }
  })),
  methods: {
    handleChooseMedia: function handleChooseMedia() {
      if (!this.multiple) {
        this.onChoose(this.selected);
      } else {
        this.onChoose(this.mediaListSelected);
      }

      this.visible = false;
    },
    onChooseMedia: function onChooseMedia(data) {
      this.selected = data;

      if (this.multiple) {
        var mediaIndex = this.mediaListSelected.indexOf(data);

        if (mediaIndex != -1) {
          this.mediaListSelected.splice(mediaIndex, 1);
        } else {
          this.mediaListSelected.push(data);
        }

        this.$store.commit("media/setListSelected", this.mediaListSelected);
      }
    },
    handleOpen: function handleOpen() {
      this.selected = null;
      this.visible = true;
      this.mediaListSelected = [];
      this.$store.commit("media/setListSelected", []);
    }
  }
};
exports.default = _default2;