"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _strategyOrder = require("@/api/strategy-order");

var _SummaryDivisionModal = _interopRequireDefault(require("./SummaryDivisionModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    SummaryDivisionModal: _SummaryDivisionModal.default
  },
  props: ["preOrderId", "refreshData"],
  data: function data() {
    return {
      data: [],
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: ""
      },
      loadingCancel: false
    };
  },
  watch: {
    refreshData: {
      handler: function handler(val) {
        if (val != -1) {
          this.fetchData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {// this.fetchData();
  },
  methods: {
    cancelSubmit: function cancelSubmit(submitId) {
      var _this = this;

      this.$confirm("Bạn chắc chắn muốn huỷ đơn này?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this.loadingCancel = true;

        _strategyOrder.submitPreOrderApi.cancel(submitId).then(function () {
          _this.fetchData();

          _this.$notify.success({
            title: "Thông báo",
            message: "Huỷ thành công!"
          });
        }).finally(function () {
          return _this.loadingCancel = false;
        });
      });
    },
    handleDetailSchools: function handleDetailSchools(row) {
      this.$emit("load:school", row.id);
    },
    handleDetail: function handleDetail(row) {
      var _this2 = this;

      this.$set(row, "loading", true);

      _strategyOrder.strategyOrderApi.divisionDetail(this.preOrderId, row.id).then(function (res) {
        _this2.$refs["summary-modal"].handleDetail(res.data, row);
      }).finally(function () {
        _this2.$set(row, "loading", false);
      });
    },
    fetchData: function fetchData() {
      var _this3 = this;

      this.loading = true;

      _strategyOrder.strategyOrderApi.reportDivision(this.preOrderId, this.listQuery).then(function (res) {
        _this3.data = res.data.divisions;
        _this3.total = res.data.total;
      }).finally(function () {
        return _this3.loading = false;
      });
    },
    handleOpen: function handleOpen(row) {
      var _this4 = this;

      this.$set(row, "loading", true);

      _strategyOrder.strategyOrderApi.divisionsOpen(this.preOrderId, row.id).then(function (res) {
        _this4.$set(row, "isClose", false);
      }).finally(function () {
        _this4.$set(row, "loading", false);
      });
    },
    handleClose: function handleClose(row) {
      var _this5 = this;

      this.$set(row, "loading", true);

      _strategyOrder.strategyOrderApi.divisionsClose(this.preOrderId, row.id).then(function (res) {
        _this5.$set(row, "isClose", true);
      }).finally(function () {
        _this5.$set(row, "loading", false);
      });
    }
  }
};
exports.default = _default;