"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _BookSummary = _interopRequireDefault(require("./PanelGroup/BookSummary"));

var _OrderSummary = _interopRequireDefault(require("./PanelGroup/OrderSummary"));

var _SchoolSummary = _interopRequireDefault(require("./PanelGroup/SchoolSummary"));

var _PreOrderSummary = _interopRequireDefault(require("./PanelGroup/PreOrderSummary"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    BookSummary: _BookSummary.default,
    OrderSummary: _OrderSummary.default,
    SchoolSummary: _SchoolSummary.default,
    PreOrderSummary: _PreOrderSummary.default
  },
  data: function data() {
    return {
      numCol: 12
    };
  },
  methods: {
    onDataNull: function onDataNull() {
      this.numCol = 8;
    }
  }
};
exports.default = _default;