"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoles = getRoles;
exports.importRoutesPath = importRoutesPath;
exports.addRole = addRole;
exports.getPermission = getPermission;
exports.getPermissions = getPermissions;
exports.updatePermission = updatePermission;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRoles() {
  return (0, _request.default)({
    url: "/role",
    method: "get"
  });
}

function importRoutesPath(data) {
  return (0, _request.default)({
    url: "/role/permissions/import",
    method: "post",
    data: data
  });
}

function addRole(data) {
  return (0, _request.default)({
    url: "/role",
    method: "post",
    data: data
  });
}

function getPermission(roleId) {
  return (0, _request.default)({
    url: "/role/".concat(roleId),
    method: "get"
  });
}

function getPermissions() {
  return (0, _request.default)({
    url: "/role/permissions",
    method: "get"
  });
}

function updatePermission(roleId, data) {
  return (0, _request.default)({
    url: "/role/".concat(roleId, "/update"),
    method: "post",
    data: data
  });
}