"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleClipboard;

var _vue = _interopRequireDefault(require("vue"));

function clipboardSuccess() {
  _vue.default.prototype.$message({
    message: 'Copy successfully',
    type: 'success',
    duration: 1500
  });
}

function clipboardError() {
  _vue.default.prototype.$message({
    message: 'Copy failed',
    type: 'error'
  });
}

function handleClipboard(text, event) {
  navigator.clipboard.writeText(text).then(function () {
    /* clipboard successfully set */
    clipboardSuccess();
  }, function () {
    clipboardError();
  });
}