"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _division = require("@/api/division");

var _DivisionModal = _interopRequireDefault(require("./components/DivisionModal"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    cities: function cities(state) {
      return state.address.cities;
    }
  })),
  components: {
    Pagination: _Pagination.default,
    DivisionModal: _DivisionModal.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.fetchTableData();
    this.$store.dispatch("address/fetchCities");
  },
  methods: {
    handleReset: function handleReset(row) {
      var _this = this;

      this.$prompt("Nhập mật khẩu mới", "Reset mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputPattern: /\w{6,}/,
        inputErrorMessage: "Mật khẩu ít nhất 6 ký tự"
      }).then(function (_ref) {
        var value = _ref.value;
        _this.listLoading = true;

        _division.divisionApi.reset(row.id, {
          password: value
        }).then(function () {
          _this.$notify.success("Reset thành công! ");
        }).finally(function () {
          return _this.listLoading = false;
        });
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.$store.commit("division/setSelected", (0, _objectSpread2.default)({}, row));
      this.$refs["dialog"].handleUpdate(row);
    },
    handleCreate: function handleCreate() {
      this.$refs["dialog"].handleCreate();
    },
    handleUnBlock: function handleUnBlock(row) {
      this.$confirm("Khách hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {});
    },
    handleBlock: function handleBlock(row) {
      var _this2 = this;

      this.$confirm("Phòng GD&DT này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _division.divisionApi.remove(row.id).then(function () {
          _this2.$notify.success({
            message: "Xoá thành công",
            title: "Thông báo"
          });

          _this2.fetchTableData();
        });
      });
    },
    fetchTableData: function fetchTableData() {
      var _this3 = this;

      this.listLoading = true;

      _division.divisionApi.findAll(this.listQuery).then(function (res) {
        _this3.tableData = res.data.data;
        _this3.total = res.data.total;
      }).finally(function () {
        _this3.listLoading = false;
      });
    }
  }
};
exports.default = _default;