"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.create = create;
exports.update = update;
exports.restore = exports.remove = exports.reset = exports.customerApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var customerApi = {
  affiliateChildren: function affiliateChildren(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/children")
    });
  },
  affiliateParent: function affiliateParent(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/parent")
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id)
    });
  },
  findAll: findAll,
  block: function block(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/block"),
      method: "PUT"
    });
  },
  unblock: function unblock(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/unblock"),
      method: "PUT"
    });
  },
  update: update
};
exports.customerApi = customerApi;

var reset = function reset(id, data) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/update/password"),
    data: data,
    method: "post"
  });
};

exports.reset = reset;

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/customer",
    method: "get",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/customer",
    method: "post",
    data: data
  });
}

function update(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}

var remove = function remove(id) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/delete"),
    method: "post"
  });
};

exports.remove = remove;

var restore = function restore(id) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/restore"),
    method: "post"
  });
};

exports.restore = restore;