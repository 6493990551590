"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Book = _interopRequireDefault(require("./Book"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Book: _Book.default
  },
  data: function data() {
    return {
      update: 0
    };
  },
  methods: {
    updateTable: function updateTable() {
      this.update += 1;
    }
  }
};
exports.default = _default;