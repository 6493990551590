import { render, staticRenderFns } from "./ClassRoomTab.vue?vue&type=template&id=61e9a4cc&"
import script from "./ClassRoomTab.vue?vue&type=script&lang=js&"
export * from "./ClassRoomTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61e9a4cc')) {
      api.createRecord('61e9a4cc', component.options)
    } else {
      api.reload('61e9a4cc', component.options)
    }
    module.hot.accept("./ClassRoomTab.vue?vue&type=template&id=61e9a4cc&", function () {
      api.rerender('61e9a4cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/school/components/ClassRoomTab.vue"
export default component.exports