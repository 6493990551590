"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionApi = void 0;

var _request = _interopRequireDefault(require("@/utils/request"));

var transactionApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/transaction',
      params: params
    });
  }
};
exports.transactionApi = transactionApi;