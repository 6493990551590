import { render, staticRenderFns } from "./Top10Book.vue?vue&type=template&id=a6f063a4&"
import script from "./Top10Book.vue?vue&type=script&lang=js&"
export * from "./Top10Book.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6f063a4')) {
      api.createRecord('a6f063a4', component.options)
    } else {
      api.reload('a6f063a4', component.options)
    }
    module.hot.accept("./Top10Book.vue?vue&type=template&id=a6f063a4&", function () {
      api.rerender('a6f063a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/admin/components/rank/Top10Book.vue"
export default component.exports