"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.findAllRecycle = findAllRecycle;
exports.create = create;
exports.update = update;
exports.remove = remove;
exports.removes = removes;
exports.deletes = deletes;
exports.restores = restores;

var _request = _interopRequireDefault(require("@/utils/request"));

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/news",
    params: listQuery
  });
}

function findAllRecycle(listQuery) {
  return (0, _request.default)({
    url: "/news/recycle",
    params: listQuery
  });
}

function create(data) {
  return (0, _request.default)({
    url: "/news",
    method: "post",
    data: data
  });
}

function update(newsId, data) {
  return (0, _request.default)({
    url: "/news/".concat(newsId, "/update"),
    method: "post",
    data: data
  });
}

function remove(newsId) {
  return (0, _request.default)({
    url: "/news/".concat(newsId, "/delete"),
    method: "post"
  });
}

function removes(data) {
  return (0, _request.default)({
    url: "/news/remove",
    method: "post",
    data: data
  });
}

function deletes(data) {
  return (0, _request.default)({
    url: "/news/delete",
    method: "post",
    data: data
  });
}

function restores(data) {
  return (0, _request.default)({
    url: "/news/restore",
    method: "post",
    data: data
  });
}