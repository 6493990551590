"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerRegistrationFormStatusTrans = exports.PartnerRegistrationFormStatus = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _PartnerRegistrationF;

var PartnerRegistrationFormStatus;
exports.PartnerRegistrationFormStatus = PartnerRegistrationFormStatus;

(function (PartnerRegistrationFormStatus) {
  PartnerRegistrationFormStatus["New"] = "NEW";
  PartnerRegistrationFormStatus["Approve"] = "APPROVE";
  PartnerRegistrationFormStatus["Reject"] = "REJECT"; //Từ chối
})(PartnerRegistrationFormStatus || (exports.PartnerRegistrationFormStatus = PartnerRegistrationFormStatus = {}));

var PartnerRegistrationFormStatusTrans = (_PartnerRegistrationF = {}, (0, _defineProperty2.default)(_PartnerRegistrationF, PartnerRegistrationFormStatus.New, 'Mới'), (0, _defineProperty2.default)(_PartnerRegistrationF, PartnerRegistrationFormStatus.Approve, 'Đã duyệt'), (0, _defineProperty2.default)(_PartnerRegistrationF, PartnerRegistrationFormStatus.Reject, 'Đã từ chối'), _PartnerRegistrationF);
exports.PartnerRegistrationFormStatusTrans = PartnerRegistrationFormStatusTrans;