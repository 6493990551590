"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionTypeTrans = exports.TransactionType = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _TransactionTypeTrans;

var TransactionType;
exports.TransactionType = TransactionType;

(function (TransactionType) {
  TransactionType["ProductCommission"] = "PRODUCT_COMMISSION";
  TransactionType["Withdraw"] = "WITHDRAW";
  TransactionType["RejectWithdraw"] = "REJECT_WITHDRAW";
})(TransactionType || (exports.TransactionType = TransactionType = {}));

var TransactionTypeTrans = (_TransactionTypeTrans = {}, (0, _defineProperty2.default)(_TransactionTypeTrans, TransactionType.ProductCommission, 'Hoa hồng từ đơn hàng'), (0, _defineProperty2.default)(_TransactionTypeTrans, TransactionType.Withdraw, 'Rút tiền'), (0, _defineProperty2.default)(_TransactionTypeTrans, TransactionType.RejectWithdraw, 'Hủy lệnh rút tiền'), _TransactionTypeTrans);
exports.TransactionTypeTrans = TransactionTypeTrans;