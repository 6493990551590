"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _strategyOrder = require("@/api/strategy-order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    summary: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    strategyOrder: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    commissionSchool: {
      type: Number,
      default: 0
    },
    commissionDivision: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loadingButton: false
    };
  },
  methods: {
    handleOpen: function handleOpen() {
      var _this = this;

      this.loadingButton = true;

      _strategyOrder.strategyOrderApi.open(this.strategyOrder.id).then(function (res) {
        _this.strategyOrder.isClose = false;

        _this.$emit("update:success");
      }).finally(function () {
        _this.loadingButton = false;
      });
    },
    handleClose: function handleClose() {
      var _this2 = this;

      this.loadingButton = true;

      _strategyOrder.strategyOrderApi.close(this.strategyOrder.id).then(function (res) {
        _this2.strategyOrder.isClose = true;

        _this2.$emit("update:success");
      }).finally(function () {
        _this2.loadingButton = false;
      });
    }
  }
};
exports.default = _default2;