var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật nhóm sách" : "Thêm mới nhóm sách",
        visible: _vm.visible,
        width: "700px",
        top: "30px"
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "bookGroup.name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.bookGroup.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form.bookGroup, "name", $$v)
                  },
                  expression: "form.bookGroup.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Sách", prop: "bookIds" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    placeholder: "",
                    multiple: "",
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    "remote-method": _vm.fetchBooks
                  },
                  model: {
                    value: _vm.form.bookIds,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bookIds", $$v)
                    },
                    expression: "form.bookIds"
                  }
                },
                _vm._l(_vm.books, function(book) {
                  return _c("el-option", {
                    key: book.id,
                    attrs: { label: _vm.renderTitleBook(book), value: book.id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v("Đồng ý")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }