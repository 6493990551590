import { render, staticRenderFns } from "./SummaryGradeTable.vue?vue&type=template&id=0a7388a1&"
import script from "./SummaryGradeTable.vue?vue&type=script&lang=ts&"
export * from "./SummaryGradeTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a7388a1')) {
      api.createRecord('0a7388a1', component.options)
    } else {
      api.reload('0a7388a1', component.options)
    }
    module.hot.accept("./SummaryGradeTable.vue?vue&type=template&id=0a7388a1&", function () {
      api.rerender('0a7388a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/strategy-order/detail/components/SummaryGradeTable.vue"
export default component.exports