"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

var _Top10Book = _interopRequireDefault(require("./components/rank/Top10Book"));

var _OrderDay = _interopRequireDefault(require("./components/chart/OrderDay"));

var _OrderMonth = _interopRequireDefault(require("./components/chart/OrderMonth"));

var _Top10BookChart = _interopRequireDefault(require("./components/chart/Top10BookChart"));

var _CustomTrackingChart = _interopRequireDefault(require("./components/chart/CustomTrackingChart.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "DashboardAdmin",
  components: {
    PanelGroup: _PanelGroup.default,
    Top10Book: _Top10Book.default,
    CustomTrackingChart: _CustomTrackingChart.default,
    OrderDay: _OrderDay.default,
    OrderMonth: _OrderMonth.default,
    Top10BookChart: _Top10BookChart.default
  }
};
exports.default = _default;