"use strict";

var _interopRequireDefault = require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("/Users/TAM/Documents/product/163_Hebec/163adminWeb/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var _vuex = require("vuex");

var _ListMediaModal = _interopRequireDefault(require("@/views/media/components/ListMediaModal"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ListMediaModal: _ListMediaModal.default
  },
  props: {
    onChoose: {
      type: Function
    },
    pathImage: String,
    uploadUrl: {
      type: String,
      default: "/media/upload"
    }
  },
  data: function data() {
    return {
      isProgressing: false,
      percentUpload: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["token"])),
  methods: {
    onChooseMedia: function onChooseMedia(data) {
      if (typeof this.onChoose == "function") {
        this.onChoose(data.thumbnail);
      }
    },
    handleChooseMedia: function handleChooseMedia() {
      this.$refs["media-modal"].handleOpen();
    },
    onProgress: function onProgress(event, file, fileList) {
      this.isProgressing = true;
      this.percentUpload = parseInt(event.percent.toFixed(0));
    },
    onError: function onError(err, file, fileList) {
      if (err.message) {
        this.$message.error(err.message);
      }

      this.isProgressing = false;
    },
    handleSuccess: function handleSuccess(res) {
      this.$emit("upload:success", res);
      this.isProgressing = false;
    },
    beforeUpload: function beforeUpload(file) {
      this.dialogLoading = true;
      var isJPG = file.type.includes("image");

      if (!isJPG) {
        this.dialogLoading = false;
        this.$message.error("Ảnh không đúng định dạng!");
      }

      return isJPG;
    }
  }
};
exports.default = _default;